import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import * as vars from '../styles/variables';

const FooterContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    color: ${vars.whiteColor};

    p {
        font-size: 14px;
        line-height: 1.4em;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        max-width: 240px;
    }
`;

const StyledAppStoreLink = styled.a`
    min-width: 150px;
    margin-left: auto;

    img {
        width: 140px;
    }

    @media (max-width: 600px) {
        margin: auto;
    }
`;

interface FooterAppStoreLinkProps {
    appStoreLink: string;
}

const FooterContentForLicenses: React.FC<FooterAppStoreLinkProps> = (props) => {
    return (
        <FooterContent>
            <p>
                <FormattedMessage id='MacAppStoreFooterDescription' />
            </p>
            <StyledAppStoreLink href={props.appStoreLink}>
                <img alt='Mac App Store Icon' src='/mas.svg' />
            </StyledAppStoreLink>
        </FooterContent>
    );
};

export default FooterContentForLicenses;
