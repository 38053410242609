import { GET_CONF_EP } from '../constants/PurchaseEndpoints';

interface GetPurchaseReturn {
    publishableKey: string;
}

const getPurchaseConf = async (
    purchaseApiUrl: string
): Promise<GetPurchaseReturn> => {
    try {
        const conf = await fetch(`${purchaseApiUrl}${GET_CONF_EP}`, {
            headers: {
                Accept: 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        });

        const response = await conf.json();

        return { publishableKey: response.publishable_key }
    } catch (error) {
        console.log('err grabbing purchase conf: ', error)
    }
};

export default getPurchaseConf;
