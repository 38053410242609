import { CART_EP } from '../constants/PurchaseEndpoints';

interface PostCartProps {
    cart: { 
        new_purchases: Array<{ product_id: string; quantity: number }>,
        upgrades: {
            [upgradeID: string]: Array<{
                upgrade_of: string;
                upgrade_quantity: number;
            }>;
        }
    };
    tax_rate: number;
    purchaseApiUrl: string;
    accessToken: string;
}

interface PostCartResponse {
    cart?: OmniStore.CartFromAPI['cart'];
    pricing?: OmniStore.CartFromAPI['pricing'];
    errorCode?: number;
    message?: string;
}

function authHeaders(accessToken: string) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    if (accessToken != undefined) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return headers
}

export const postCart = async ({
    accessToken,
    cart,
    purchaseApiUrl,
    tax_rate,
}: PostCartProps): Promise<PostCartResponse> => {
    try {
        const checkoutCart = await fetch(`${purchaseApiUrl}${CART_EP}`, {
            body: JSON.stringify({ 
                cart, 
                tax_rate: tax_rate.toFixed(2), 
            }),
            headers: authHeaders(accessToken),
            method: 'POST',
            mode: 'cors',
        });

        const response = await checkoutCart.json();

        if (response.errors) {
            return { 
                errorCode: response.errors[0].code, 
                message: response.errors[0].message
            }
        } else {
            return response;
        }
    } catch (error) {
        console.log('error: ', error)
    }
};
