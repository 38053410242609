import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

import { DropdownElements } from './OmniHeader';
import { handleSignOut } from '../utils/handleSignInSignOut';
import { toggleSignInForm } from '../actions/accounts';


const SignInButton = styled.li`
    list-style-type: none;
    padding-left: 0;
    margin: 0 15px;
    padding: 0;
    cursor: pointer;
`;

const SignOutButton = styled.li`
    list-style-type: none;
    margin: 0 10px;
    padding: 10px 20px;

    span {
        margin-left: 10px;
    }
`;

const SignOutButtonSmall = styled.li`
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-decoration: underline;
`;

const SmallElements = styled.ul`
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;

    li {
        padding: 0 !important;
        margin: 0 !important;
    }
`;

interface SignInProps {
    fullDeal?: boolean;
    onSignInClick?: () => void;
    smaller: boolean;
}

const SignIn: React.FC<SignInProps> = (props) => {
    const accounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);
    const dispatch = useDispatch();
    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const showSigninForm = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.showSigninForm);
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const handleSignInClick = (): void => {
        if (props.onSignInClick) {
            props.onSignInClick();
        }
        dispatch(toggleSignInForm({ display: !showSigninForm }));
    };

    const location = useLocation();

    if (location.pathname === '/signin') {
        return null;
    }
    if (!accounts.username) {
        return (
            <SignInButton onClick={() => handleSignInClick()}>
                {!showSigninForm ? 'Sign in' : 'Cancel'}
            </SignInButton>
        );
    }

    if (accounts.username) {
        if (props.smaller) {
            return (
                <SmallElements>
                    <li>
                        <a href={accountsApiUrl}>Manage Account</a>
                    </li>
                    <SignOutButtonSmall onClick={(): Promise<void> => handleSignOut(accessToken, accountsApiUrl)}>
                        <FormattedMessage id='SignOut' />
                    </SignOutButtonSmall>
                </SmallElements>
            );
        }
        return (
            <DropdownElements>
                <li>
                    <a href={accountsApiUrl}>Manage Account</a>
                </li>
                <SignOutButton onClick={(): Promise<void> => handleSignOut(accessToken, accountsApiUrl)}>
                    <span>
                        <FormattedMessage id='SignOut' />
                    </span>
                </SignOutButton>
            </DropdownElements>
        );
    }

    return null;
};

export default SignIn;
