import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer, { RootState } from '../reducers';

const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
});
const configureStore = (initialStore?: RootState): Store => {
    const store = createStore(
        rootReducer,
        initialStore,
        composeEnhancers(applyMiddleware())
    );

    return store;
};

export default configureStore;
