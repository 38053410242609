import { store } from '../index';
import {
    MANAGED_TEAM_LICENSES_EP,
    USER_EP,
    PURCHASES_EP,
} from '../constants/AccountsEndpoints';
import {
    setExpandedUserInfo,
    setManagedTeamLicensesInfo,
    setPurchasesData
} from '../actions/accounts';

import { Action } from '../actions/types';

export const getUserInfo = async (accessToken: string, accountsApiUrl: string): Promise<Action> => {
    try {
        const userInfo = await fetch(`${accountsApiUrl}${USER_EP}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        });
        const response = await userInfo.json();

        if (response) {
            // console.log('The expanded `userinfo` has been set successfully.')
            return store.dispatch(setExpandedUserInfo({
                userinfo: response.userinfo
            }))
        } else {
            console.log('There was an issue getting the expanded `userinfo`.')
        }
    } catch (error) {
        console.log('Error fecthing userInfo: ', error)
    }
};

export const getManagedTeamLicensesInfo = async (accessToken: string, accountsApiUrl: string): Promise<Action> => {
    const managedLicenseInfo = await fetch(`${accountsApiUrl}${MANAGED_TEAM_LICENSES_EP}`, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
    });

    const response = await managedLicenseInfo.json();

    if (response) {
        // console.log("The user's `managedTeamLicenses` have been set successfully.")
        return store.dispatch(setManagedTeamLicensesInfo({
            managedTeamLicenses: response.managed_team_licenses
        }))
    } else {
        console.log("There was an issue getting the user's `managedTeamLicenses`.")
    }
};

export const getPurchaseData = async (accessToken: string, accountsApiUrl: string): Promise<Action> => {
    try {
        const purchaseData = await fetch(`${accountsApiUrl}${PURCHASES_EP}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        });
    
        const response = await purchaseData.json();
    
        if (response) {
            // console.log('Successfully pulled purchases data')
            return store.dispatch(setPurchasesData({
                purchasesData: response.purchases
            }))
        } 
    } catch (error) {
        console.log('Did not pull purchases data')
        console.log('There was a fetch error while retriving purchase data: ', error)
    }
};
