import { CREATE_REFUND_EP } from '../constants/PurchaseEndpoints';

interface PostCreateRefund extends OmniStore.CommonPurchaseAPIProperties {
    customer_id: string;
    payment_intent_id: string;
}
interface CreateRefundReturn {
    message: string;
    id: string;
    amount: number;
}

const postCreateRefund = async ({
    accessToken,
    customer_id,
    payment_intent_id,
    purchaseApiUrl,
}: PostCreateRefund): Promise<PromiseRejectedResult | CreateRefundReturn> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseApiUrl}${CREATE_REFUND_EP({
                customer_id: customer_id,
                payment_intent_id,
            })}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            })
            .then((response) => resolve(response.json()))
            .catch((err) => {
                console.log('err', err);
                reject(err);
            });
    });
};

export default postCreateRefund;
