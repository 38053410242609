const initialState: OmniStore.GlobalProductVersionsState = {
    omnifocus: "OmniFocus 4",
    omnigraffle: "OmniGraffle 7",
    omnioutliner: "OmniOutliner 5",
    omniplan: "OmniPlan 4",
};

const productVersions = (state = initialState) => {
    return state;
}

export default productVersions;