import { UPDATE_PAYMENT_INTENT_EP } from '../constants/PurchaseEndpoints';
import { Metadata } from '@stripe/stripe-js';

interface PostPaymentIntentProps extends OmniStore.CommonPurchaseAPIProperties {
    cart: { 
        new_purchases: Array<{ product_id: string; quantity: number }>,
        upgrades: {
            [upgradeID: string]: Array<{
                upgrade_of: string;
                upgrade_quantity: number;
            }>;
        }
    };
    customer_id: string;
    jurisdiction?: string;
    metadata?: Metadata;
    paymentIntentID: string;
    paymentMethodID?: string;
    setup_future_usage?: boolean;
    tax_rate?: string;
    updatedAmount: number | null;
}

export const postUpdatePaymentIntent = async ({
    accessToken,
    cart,
    customer_id,
    jurisdiction,
    metadata,
    paymentIntentID,
    paymentMethodID,
    purchaseApiUrl,
    setup_future_usage = true,
    tax_rate,
    updatedAmount,
}: PostPaymentIntentProps): Promise<boolean> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseApiUrl}${UPDATE_PAYMENT_INTENT_EP({
                customer_id: customer_id,
                payment_intent_id: paymentIntentID,
            })}`,
            {
                body: JSON.stringify({
                    amount: updatedAmount ? updatedAmount : null,
                    cart: cart,
                    currency: 'usd',
                    jurisdiction: jurisdiction ? jurisdiction : null,
                    metadata: metadata ? metadata : null,
                    payment_method: paymentMethodID,
                    setup_future_usage: setup_future_usage
                        ? 'off_session'
                        : null,
                    tax_rate: tax_rate,
                }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        localStorage.setItem('cs', res.cs);
                        localStorage.setItem('piID', res.id);
                        resolve(true);
                        return [res.cs, res.id];
                    } else if (res.errors) {
                        console.log(res.errors);
                        return reject(true);
                    }
                    return reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                reject(err);
            });
    });
};
