import { CREATE_PAYMENT_INTENT_EP } from '../constants/PurchaseEndpoints';
import { Metadata } from '@stripe/stripe-js';

interface PostPaymentIntentProps extends OmniStore.CommonPurchaseAPIProperties {
    cart: { 
        new_purchases: Array<{ product_id: string; quantity: number }>,
        upgrades: {
            [upgradeID: string]: Array<{
                registration_identifier: string;
                upgrade_quantity: number;
            }>;
        }
    };
    customer_id: string;
    initialAmount: number;
    metadata?: Metadata;
}

interface PostPaymentIntentResponse {
    cs?: string;
    id?: string;
    errors?: Array<{code: number, message: string}>;
}

/**
 * Post a Payment Intent
 * @param {number} initialAmount
 * @return {Promise}
 */

export const postPaymentIntent = async ({
    accessToken,
    cart,
    customer_id,
    initialAmount,
    purchaseApiUrl, 
}: PostPaymentIntentProps): Promise<PostPaymentIntentResponse> => {
    try {
        const paymentIntent = await fetch(`${purchaseApiUrl}${CREATE_PAYMENT_INTENT_EP({
            customer_id: customer_id,
        })}`,  
            {
                body: JSON.stringify({
                    amount: initialAmount,
                    cart: cart,
                    currency: 'usd',
                    setup_future_usage: 'off_session',
                }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
        });

        const response = await paymentIntent.json();
        
        if (response) {
            if (!response.errors) {
                sessionStorage.setItem('paymentIntentClientSecret', response.cs);
                sessionStorage.setItem('paymentIntentID', response.id);
            }
            return response;
        }
    } catch (errors) {
        console.log('error: ', errors)
    }
}