import styled from 'styled-components/macro';

const StoreFrontContainer = styled.div`
    margin: auto;
    padding: 0 20px;
    position: relative;
    max-width: 1024px;
    animation: showLoad 0.5s;

    @keyframes showLoad {
        0% {
            opacity: 0;
            transform: scale(0.95);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

export default StoreFrontContainer;
