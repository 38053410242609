import { TAXRATE_EP } from '../constants/TaxServiceEndpoints';

interface TaxServiceProps {
    city: string;
    country: string;
    line1: string;
    line2?: string;
    postalCode: string;
    state: string;
    taxServiceURL: string;
}

interface TaxInfo {
    jurisdiction: string;
    tax_percentage: number;
    tax_rate: string;
}

interface PostTaxRateResponse {
    has_tax_info: boolean;
    // omni_tax_string?: string;
    tax_info?: TaxInfo;
    errors?: Array<{code: number, message: string}>;
}

const postTaxRate = async (
    props: TaxServiceProps
): Promise<PostTaxRateResponse> => {
    try {
        const taxrate = await fetch(`${props.taxServiceURL}${TAXRATE_EP}`, {
            body: JSON.stringify({
                city: props.city,
                country: props.country,
                line1: props.line1,
                line2: props.line2,
                postal_code: props.postalCode,
                state: props.state,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors'
        });

        const response = await taxrate.json();

        if (response) {
            return response;
        } else {
            console.log('Error getting tax rate');
        }
    } catch (errors) {
        console.log('err', errors)
    }
};

export default postTaxRate;
