import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { RowElement } from './RowElement';

import { removeLicenseUpgrade, updateUpgradeQuantity } from '../actions/cart';
import * as vars from '../styles/variables';

const SelectedPrice = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 500px) {
        font-size: 20px;
        flex-direction: column;
    }
`;

const FakePriceBox = styled.span`
    display: inline-block;
    border-radius: 20px;
    padding: 10px 20px;
    height: initial;
    line-height: 21px;
    margin: 10px 10px 10px 0;
    font-size: 15px;
    font-family: quatro;
    background-color: #fff;
    border: 1px solid rgba(85, 197, 235, 1);
    color: #000;
    font-weight: 500;
`;

// eslint-disable-next-line
interface QuantityProps {
    borderHighlight: boolean;
}

const Quantity = styled.input<QuantityProps>`
    width: 65px;
    padding: 6px 8px;
    margin: 0 10px;
    border-color: ${(props): string =>
        props.borderHighlight ? 'red' : 'rgba(85, 197, 235, 1)'};
    font-family: quatro;
    font-size: 16px;
    line-height: 20px;
    height: unset;
`;

const Topper = styled.div`
    font-size: 20px;
    text-align: left;
    margin: 0 10px 0 auto;
    @media (max-width: 500px) {
        margin: 10px auto;
    }
`;

const PriceTag = styled.span`
    font-size: 20px;
    font-weight: 400;
`;

const PriceString = styled.span`
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px;
`;

const Update = styled.a`
    font-size: 14px;
    margin: 0 10px 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
`;

const CartItemAppHeader = styled.h3`
    margin: 5px 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4em;

    @media (max-width: 500px) {
        font-size: 20px;
    }
`;

const CartItemDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const UpgradeCartItemHolderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid rgba(105, 201, 237, 1);
    color: ${vars.blackColor};

    @media (max-width: 500px) {
        padding: 10px;
        border: none;
        border-bottom: 1px solid #909090;
        border-radius: unset;
    }

    @media (max-width: 800px) {
        margin: 20px 0;
    }
`;

interface LicenseCartItemHolderProps {
    item: OmniStore.LicenseUpgradeCartItem;
    simple: boolean;
}

const UpgradeCartItemHolder: React.FC<LicenseCartItemHolderProps> = (props) => {
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();

    const {
        item,
        simple
    } = props;

    const productVersion = useSelector((state: OmniStore.GlobalReduxState) => state.currentMajorVersions[item.shortName]);
    const [quantityError, setQuantityError] = React.useState<boolean>(false);
    const initialQuantity = item.quantity ? item.quantity : 1;
    const [quantity, setQuantity] = React.useState(initialQuantity);
    const humanPriceForSelectedVersion = item.upgradePrice/100;

    const removeItemHandler = (): void => {
        dispatch(
            removeLicenseUpgrade({
                item: {
                    ...item,
                },
                noWrite: false,
            })
        );
        trackEvent({
            action: 'Cart Change',
            category: 'Ecommerce',
            name: 'Item removed: ' + item.displayName, // optional
        });
    };

    const convertToTeamHandler = (): void => {
        if (quantity === 0) {
            setQuantityError(true);
            return;
        }
        setQuantityError(false);

        dispatch(
            updateUpgradeQuantity({
                item: {
                    ...item,
                    quantity: quantity,
                },
                noWrite: true,
            })
        );
        trackEvent({
            action: 'Cart Change',
            category: 'Ecommerce',
            name: 'Convert to team: ' + item.displayName, // optional
        });
    };

    const upgrade_to = item.standardOrProUpgrade === 'PRO' 
        ? `${productVersion} PRO`
        : item.shortName === 'omnioutliner'
        ? `${productVersion} Essentials`
        : `${productVersion}`
    ;

    return (
        <UpgradeCartItemHolderContainer>
            <CartItemDetails>
                <CartItemAppHeader>{upgrade_to}</CartItemAppHeader>
                <div>Upgrading from: {item.displayName}</div>
                {!simple && (
                    <div>
                        <RowElement alignMiddle>
                            <SelectedPrice>
                                <FakePriceBox>
                                    <FormattedNumber
                                        value={humanPriceForSelectedVersion}
                                        style={`currency`}
                                        currency='USD'
                                    />
                                    {'/'}
                                    { (item.shortName === 'omnioutliner' && item.standardOrProUpgrade === 'STANDARD') 
                                        ? 'Essentials license upgrade'
                                        : item.standardOrProUpgrade === 'STANDARD'
                                        ? 'Standard license upgrade'
                                        : 'Pro license upgrade'
                                    }
                                </FakePriceBox>
                            </SelectedPrice>

                            <Topper>
                                <PriceString>
                                    <FormattedMessage id='priceString' />
                                    {': '}
                                </PriceString>
                                <PriceTag>
                                    <FormattedNumber
                                        value={
                                            humanPriceForSelectedVersion *
                                            item.quantity
                                        }
                                        style={`currency`}
                                        currency='USD'
                                    />
                                </PriceTag>
                            </Topper>
                        </RowElement>
                        <RowElement alignEnd alignMiddle>
                            <div>
                                <label
                                    style={{
                                        fontSize: '14px',
                                    }}
                                >
                                    Quantity:
                                </label>
                                <Quantity
                                    type='number'
                                    placeholder='1'
                                    min='1'
                                    max={item.seatsAvailableToUpgrade}
                                    pattern='[1-9]*'
                                    value={quantity}
                                    borderHighlight={quantityError}
                                    onChange={(e): void => setQuantity(Number(e.target.value))}
                                />
                            </div>
                            {quantity < item.seatsAvailableToUpgrade && (
                                <Update
                                    id="remove-update"
                                    onClick={(): void => convertToTeamHandler()}
                                >
                                    Update
                                </Update>
                            )}
                            <Update 
                                id="remove-update"
                                onClick={(): void => removeItemHandler()}
                            >
                                Remove
                            </Update>
                        </RowElement>
                    </div>
                )}
                {simple && (
                    <h3>
                        <FormattedNumber
                            value={humanPriceForSelectedVersion}
                            style={`currency`}
                            currency='USD'
                        />{' '}
                        /{' '}
                        {/* <FormattedMessage
                            id={
                                props.item.standardOrPro === 'STANDARD'
                                    ? 'STANDARD'
                                    : 'PRO'
                            }
                        /> */}
                    </h3>
                )}
            </CartItemDetails>
        </UpgradeCartItemHolderContainer>
    );
};

export default UpgradeCartItemHolder;
