import * as React from 'react';
import styled from 'styled-components/macro';
import { List } from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import StoreFrontContainer from './StoreContainer';
import NewButton from './NewButton';
import { store } from '..';
import { toggleSignInForm } from '../actions/accounts';
import { RowElement } from './RowElement';
import { getPurchaseData } from '../utils/getUserData';
import postRegisterLicenseKey from '../utils/postRegisterLicenseKey';
import getEligibleUpgrades from '../utils/getUpgradeItems';
import * as vars from '../styles/variables';

const Note = styled.div`
    margin: 10px auto;
    padding: 20px 10px 30px;
    font-weight: 500;
    background-color: rgba(251, 207, 107, 0.3);
    border: 3px solid rgba(251, 207, 107, 1);
    border-radius: 10px;

    h3 {
        font-size: 20px;
        text-align: center;
        margin: 10px auto 15px;
        font-weight: 700;
    }

    p {
        width: 90%;
        margin: auto;
    }
`;

const StylelessList = styled.li`
    list-style-type: none;
`;

const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 20px auto;
    padding: 15px 40px;
    color: ${vars.whiteColor};

    > h2, a, p {
        color: ${vars.whiteColor};
    }

    h2 {
        font-size: 1.3em;
        text-align: center;
    }

    @media (max-width: 600px) {
        padding: 15px 10px;
    }
`;

const LostLicenseLink = styled.div`
    text-align: center;
    color: #000;
    font-size: 13px;
    text-decoration: underline;
    margin: 20px;
    cursor: pointer;
`;

const LicenseOwner = styled.input`
    width: 100%;
    margin: 10px;
    font-size: 16px;
    padding: 10px;
`;

const LicenseKey = styled.input`
    width: 100%;
    margin: 10px;
    font-size: 16px;
    padding: 10px;
`;

const RegisterLicense: React.FC = () => {
    interface Error {
        error: boolean;
        errorHeader: string;
        errorString: string;
    }
    const intl = useIntl();
    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const accessToken = store.getState().accounts.accessToken;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<Error>({
        error: false,
        errorHeader: 'licenseAlreadyRegisteredHeader',
        errorString: '',
    });
    const [success, setSuccess] = React.useState(false);
    const [licenseKey, setKey] = React.useState<string>('');
    const [licenseOwner, setOwner] = React.useState<string>('');
    const dispatch = useDispatch();

    const purchasesData = useSelector(
        (state: OmniStore.GlobalReduxState) => state.accounts.purchasesData
    );

    const registerLicenseKey = async () => {
        setLoading(true);

        const registerKey = await postRegisterLicenseKey({
            accessToken,
            accountsApiUrl,
            licenseKey,
            licenseOwner
        });

        if (registerKey) {
            if (registerKey.status === 'success') {
                setSuccess(true);
                setKey('');
                setOwner('');
                getPurchaseData(accessToken, accountsApiUrl);
                getEligibleUpgrades({accessToken, purchaseApiUrl});
                setError({
                    error: false,
                    errorHeader: 'licenseAlreadyRegisteredHeader',
                    errorString: '',
                })
                setLoading(false);
            } else if (registerKey.status === 'error') {
                if (registerKey.message) {
                    setError({
                        error: true,
                        errorHeader: 'errorMessagePlainAndShort',
                        errorString: 'doubleCheckLicense',
                    });
                    setLoading(false);
                    return;
                } else {
                    const errorCode = registerKey.messages[0].code;
                    switch(errorCode) {
                        case 10204:
                            setError({
                                error: true,
                                errorHeader: 'licenseErrorForBundledLicenseHeader',
                                errorString: 'licenseErrorForBundledLicenseBody',
                            });
                            setLoading(false);
                            break;
                        case 10201:
                            setError({
                                error: true,
                                errorHeader: 'licenseAlreadyRegisteredHeader',
                                errorString: 'licenseAlreadyRegisteredBody',
                            });
                            setLoading(false);
                            break;
                        case 10205:
                            setError({
                                error: true,
                                errorHeader: 'licenseErrorForBundledLicenseHeader',
                                errorString: 'licenseErrorForBundledLicenseBody',
                            });
                            setLoading(false);
                            break;
                        default:
                            setError({
                                error: true,
                                errorHeader: 'errorMessagePlainAndShort',
                                errorString: 'doubleCheckLicense',
                            });
                            setLoading(false);
                            break;
                    }
                    return;
                }
            }
        } 
        return;
    }

    return (
        <StoreFrontContainer>
            <RegisterContainer>
                <h2>
                    <FormattedMessage id='RegisterCurrentLicenseTitle' />
                </h2>
                <p>
                    <FormattedMessage id='RegisterCurrentLicenseInformationalText' />{' '}
                    <a href={accountsApiUrl}>
                        <FormattedMessage id='viewRegisteredLicenses' />
                    </a>
                </p>
                {purchasesData !== undefined && (
                    <div>
                        {purchasesData.filter((license) => 'key' in license)
                            .length > 0 && (
                            <h3>Registered keys on this account:</h3>
                        )}
                        <ul>
                            {purchasesData
                                .filter((license) => 'key' in license)
                                .map((license) => {
                                    return (
                                        <StylelessList key={license.key}>
                                            {license.description}
                                        </StylelessList>
                                    );
                                })}
                        </ul>
                    </div>
                )}
                {error.error === true && (
                    <Note>
                        <h3>
                            <FormattedMessage id={error.errorHeader} />
                        </h3>
                        <p>
                            <FormattedMessage id={error.errorString} />
                        </p>
                    </Note>
                )}
                {success && (
                    <Note>Successfully registered your license key!</Note>
                )}
                {loading ? (
                    <List />
                ) : (
                    <React.Fragment>
                        <LicenseOwner
                            placeholder={intl.formatMessage({
                                id: 'LicenseOwnerNamePlaceholder',
                            })}
                            onChange={(e): void => setOwner(e.target.value)}
                        />
                        <LicenseKey
                            placeholder={intl.formatMessage({
                                id: 'LicenseOwnerKeyPlaceholder',
                            })}
                            onChange={(e): void => setKey(e.target.value)}
                        />
                    </React.Fragment>
                )}
                {accessToken === undefined && (
                    <div>
                        <NewButton
                            id='SignInToRegisterLicense'
                            onClick={() =>
                                dispatch(toggleSignInForm({ display: true }))
                            }
                        >
                            Sign in to register a license
                        </NewButton>
                    </div>
                )}

                <RowElement>
                    <LostLicenseLink>
                        <a href='https://legacy.store.omnigroup.com/lost/'>
                            Lost your license key?
                        </a>
                    </LostLicenseLink>
                    {accessToken !== undefined && (
                        <NewButton
                            alignRight
                            disabled={
                                licenseKey.length === 0 ||
                                licenseOwner.length === 0
                            }
                            id='RegisterLicense'
                            onClick={() => registerLicenseKey()}
                        >
                            Register License
                        </NewButton>
                    )}
                </RowElement>
            </RegisterContainer>
        </StoreFrontContainer>
    );
};

export default RegisterLicense;
