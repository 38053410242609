import { combineReducers } from 'redux';
import { Action } from '../actions/types';

import accounts from './accounts';
import cart from './cart';
import stripe from './stripe';
import subscriptions from './subscriptions';
import apiURLs from './apiURL';
import upgrades from './upgrades';
import currentMajorVersions from './productVersion';

const rootReducer = combineReducers({
    accounts,
    apiURLs,
    cart,
    currentMajorVersions,
    stripe,
    subscriptions,
    upgrades,
});

export type RootState = ReturnType<typeof rootReducer>;

const reducer = (
    state: RootState,
    action: Action
): RootState => {
    return rootReducer(state, action);
};

export default reducer;