import { CREATE_SUBSCRIPTION_EP } from '../constants/PurchaseEndpoints';
import { Metadata, PaymentMethod } from '@stripe/stripe-js';

interface Plans {
    plan_id: string;
    quantity: number;
}
interface PostSubscribeProps extends OmniStore.CommonPurchaseAPIProperties {
    customer_id: string;
    email: string;
    jurisdiction?: string;
    metadata?: Metadata;
    payment_method_id?: string | PaymentMethod;
    plans: Array<Plans>;
    tax_rate: string;
}

const postSubscribe = async ({
    accessToken,
    customer_id,
    email,
    metadata,
    jurisdiction,
    payment_method_id,
    plans,
    purchaseApiUrl,
    tax_rate = '0',
}: PostSubscribeProps): Promise<boolean> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseApiUrl}${CREATE_SUBSCRIPTION_EP({
                customer_id: customer_id,
            })}`,
            {
                body: JSON.stringify({
                    customer_id: customer_id,
                    email: email,
                    jurisdiction: jurisdiction ? jurisdiction : null,
                    metadata: metadata ? metadata : null,
                    payment_method_id: payment_method_id,
                    plans: plans,
                    tax_rate: tax_rate,
                }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve(true);
                    } else if (res.errors) {
                        console.log(res.errors);
                        reject(true);
                    }
                    reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                reject({ err: err, plans: plans });
            });
    });
};

export default postSubscribe;
