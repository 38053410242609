import { ActionType, Action } from '../actions/types';

export const clearSubscriptions = (): Action => {
    return {
        type: ActionType.CLEAR_SUBSCRIPTIONS,
    }
}

export const setInitialSubscriptions = ({ subscriptions }): Action => {
    return {
        subscriptions,
        type: ActionType.ADD_SUBSCRIPTIONS,
    }
}