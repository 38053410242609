import React, { useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Error } from './Error';
import NewButton from './NewButton';
import * as vars from '../styles/variables';

import postTaxRate from '../utils/postTaxRate';
import { postUpdatePaymentIntent } from '../utils/postUpdatePaymentIntent';


const BillingAddressForm = styled.form`
    max-width: 500px;
    margin: auto;
    
    label {
        font-weight: 500;
    }

    > h3 {
        color: ${vars.whiteColor};
        text-align: center;
        font-size: 30px;
    }
`;

const CompanyAddressSection = styled.div``;

const OptionalBusinessSection = styled.div`
    /* margin: 20px 0; */
    padding: 10px;
    background-color: #f6f8fa;

    > * {
        font-size: 14px;
    }

    > div {
        background-color: #f6f8fa;
    }

    span {
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }

    p {
        font-size: 14px;
        margin: 5px 0;
    }

    input {
        margin: 5px 0 10px 0;
        font-size: 12px;
        padding: 8px 6px;
        height: unset;
    }

    form {
        font-size: 12px;
    }
`;

const FormContainer = styled.div`
    background-color: ${vars.whiteColor};
    padding: 60px;
    border-radius: 7px;
    margin: auto;
`;

const InputRowWrap = styled.div`
    margin: 10px 0;
`;

const InputField = styled.input`
    width: 100%;
`;

const Grouping = styled.div`
    margin: 20px 0;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px auto;
`;


interface TaxRateInformation {
    jurisdiction?: string;
    tax_rate: string;
}

interface BillingDetailsProps {
    addressError: boolean;
    additionalNotes: string;
    cardError: boolean;
    cardErrorMessage: string;
    companyCustomerAddressLineOne: string;
    companyCustomerAddressLineTwo: string;
    companyCustomerCity: string;
    companyCustomerCountry: string;
    companyCustomerPostalCode: string;
    companyCustomerRegion: string;
    companyName: string;
    customerAddressLineOne: string;
    customerAddressLineTwo: string;
    customerCity: string;
    customerCountry: string;
    customerNameOnCard: string;
    customerPostalCode: string;
    customerRegion: string;
    customerVATID: string;
    newCustomerStripeID?: string;
    serverError: string;
    setAddressLineOne: (address: string) => void;
    setAddressLineTwo: (address: string) => void;
    setAdditionalNotes: (note: string) => void;
    setAddressError: (error: boolean) => void;
    setCity: (city: string) => void;
    setCountry: (country: string) => void;
    setCompanyAddressLineOne: (address: string) => void;
    setCompanyAddressLineTwo: (address: string) => void;
    setCompanyCity: (city: string) => void;
    setCompanyCountry: (country: string) => void;
    setCompanyName: (companyName: string) => void;
    setCompanyCustomerPostalCode: (zip: string) => void;
    setCompanyRegion: (region: string) => void;
    setCustomerVATID: (id: string) => void;
    setCustomerPostalCode: (zip: string) => void;
    setNameOnCard: (name: string) => void;
    setRegion: (region: string) => void;
    setServerError: (error: string) => void;
    setTaxDetermined: (taxDetermined: boolean) => void;
    setTaxRate: ({jurisdiction, tax_rate}: TaxRateInformation) => void;
    taxDetermined: boolean;
}

const BillingDetails = (props: BillingDetailsProps) => {
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const taxserviceURL = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.taxserviceURL);
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const cartFromAPI = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.serverCart,}));

    const [enforceBillingForm, setEnforceBillingForm] = useState<{errorMessage: string, show: boolean}>({ errorMessage: '', show: false });
    const [addCompanyNameOrVAT, setAddCompanyNameOrVAT] = useState<boolean>(false);
    const [addCompanyAddress, setAddCompanyAddress] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const signedInAccount = useSelector((state: OmniStore.GlobalReduxState) => ({ account: state.accounts.expandedUserInfo,}));

    const { 
        addressError,
        additionalNotes,
        cardError,
        cardErrorMessage,
        companyCustomerAddressLineOne,
        companyCustomerAddressLineTwo,
        companyCustomerCity,
        companyCustomerCountry,
        companyCustomerPostalCode,
        companyCustomerRegion,
        companyName,
        customerAddressLineOne,
        customerAddressLineTwo,
        customerCity,
        customerCountry,
        customerNameOnCard,
        customerPostalCode,
        customerRegion,
        customerVATID,
        newCustomerStripeID,
        setAddressLineOne,
        setAddressLineTwo,
        setAdditionalNotes,
        setAddressError,
        setCompanyName,
        setCity,
        setCountry,
        setRegion,
        setServerError,
        setTaxDetermined,
        setTaxRate,
        setCompanyAddressLineOne,
        setCompanyAddressLineTwo,
        setCompanyCity,
        setCompanyCountry,
        setCompanyCustomerPostalCode,
        setCompanyRegion,
        setCustomerPostalCode,
        setCustomerVATID,
        setNameOnCard,
    } = props;




    const determineTaxAndUpdatePaymentIntentWithOptionalCompanyInfo = async (event: React.FormEvent): Promise<void> => {
        setLoading(true);
        event.preventDefault();
        setEnforceBillingForm({
            errorMessage: '',
            show: false,
        });

        let taxRate: string;

        if (customerCountry === 'US') {
            if (customerPostalCode.length < 5) {
                setEnforceBillingForm({
                    errorMessage:
                        'Make sure the ZIP code is valid for a United States address.',
                    show: true,
                });
                return;
            }
            if (customerRegion === 'WA') {
                if (
                    !customerPostalCode.startsWith('98') &&
                    !customerPostalCode.startsWith('99')
                ) {
                    setEnforceBillingForm({
                        errorMessage:
                            'Make sure the ZIP code is valid for a Washington address.',
                        show: true,
                    });
                    return;
                }
            }
            const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
            if (!zipCodePattern.test(customerPostalCode)) {
                setEnforceBillingForm({
                    errorMessage:
                        'Make sure the ZIP code is valid for a United States address.',
                    show: true,
                });
                return;
            }
        }

        if (
            customerRegion === '' ||
            customerNameOnCard === '' ||
            customerCountry === '' ||
            customerPostalCode === '' ||
            customerCity === ''
        ) {
            setEnforceBillingForm({
                errorMessage:
                    'Make sure the entire billing address is complete.',
                show: true,
            });
            return;
        }

        if (
            cartFromAPI.cart.pricing === undefined &&
            cartFromAPI.cart.message === undefined
        ) {
            setServerError(
                'The purchase server is not responding. Please try again later.'
            );
            return;
        }
        let jurisdictionForStripeStorage: string | null;
        const piID = sessionStorage.getItem('paymentIntentID');
        const licensesJson = cartItems.cart.licenses.map((item) => {
            if (item.standardOrPro === 'PRO') {
                return {
                    product_id: item.productID,
                    quantity: item.quantity,
                };
            }
            return {
                product_id: item.productID.replace('_PRO', '_STD'), //delete _PRO if not PRO
                quantity: item.quantity,
            };
        });

        const upgradeJson = {};
        cartItems.cart.upgrades.forEach((item) => {
            const upgrade_to = item.upgradeTo;
            const upgradeItem = {
                registration_identifier: item.registration_identifier,
                upgrade_quantity: item.quantity
            }
            if (!upgradeJson[upgrade_to]) {
                upgradeJson[upgrade_to] = [];
            }
            upgradeJson[upgrade_to].push(upgradeItem);
        })

        let customerID: string;

        if (signedInAccount !== undefined) {
            if (signedInAccount.account.customer_id !== undefined) {
                customerID = signedInAccount.account?.customer_id[0];
            } else {
                customerID = newCustomerStripeID;
            }
        } else if (newCustomerStripeID !== undefined) {
            customerID = newCustomerStripeID;
        }

        const taxserviceCall = await postTaxRate({
            city: customerCity,
            country: customerCountry,
            line1: customerAddressLineOne,
            line2: customerAddressLineTwo,
            postalCode: customerPostalCode,
            state: customerRegion,
            taxServiceURL: taxserviceURL,
        });

        if (taxserviceCall.errors) {
            console.log(taxserviceCall.errors)
            setAddressError(true);
            setLoading(false);
            return;
        }

        if (taxserviceCall.has_tax_info) {
            setAddressError(false);
            taxRate = taxserviceCall.tax_info.tax_rate
            jurisdictionForStripeStorage = taxserviceCall.tax_info.jurisdiction
            setTaxRate({
                jurisdiction: jurisdictionForStripeStorage,
                tax_rate: taxRate,
            });

            let customerID: string;
            if (signedInAccount !== undefined) {
                if (signedInAccount.account.customer_id !== undefined) {
                    customerID = signedInAccount.account?.customer_id[0];
                } else {
                    customerID = newCustomerStripeID;
                }
            } else if (newCustomerStripeID !== undefined) {
                customerID = newCustomerStripeID;
            }

            let updatedAmount;
            if (taxRate === '0' && cartFromAPI.cart.pricing !== undefined) {
                updatedAmount = cartFromAPI.cart.pricing.final_price ?? null;
            } else if (cartFromAPI.cart.pricing !== undefined) {
                updatedAmount =
                    cartFromAPI.cart.pricing.final_price +
                    parseFloat(taxRate) * cartFromAPI.cart.pricing.final_price;
            } else {
                updatedAmount = null;
            }
            if (
                piID !== null &&
                (cartItems.cart.licenses.length > 0 || cartItems.cart.upgrades.length) &&
                customerID !== undefined
            ) {
                postUpdatePaymentIntent({
                    accessToken,
                    cart: {
                        new_purchases: licensesJson,
                        upgrades: upgradeJson,
                    },
                    customer_id: customerID,
                    jurisdiction: jurisdictionForStripeStorage ?? undefined,
                    metadata: {
                        additionalNotes: additionalNotes,
                        companyCustomerCountry: companyCustomerCountry,
                        companyCustomerPostalCode: companyCustomerPostalCode,
                        companyCustomerRegion: companyCustomerRegion,
                        companyName: companyName,
                        companyaddressOne: companyCustomerAddressLineOne,
                        companyaddressTwo: companyCustomerAddressLineTwo,
                        companycity: companyCustomerCity,
                        customerVATID,
                    },
                    paymentIntentID: piID,
                    purchaseApiUrl,
                    tax_rate: taxRate,
                    updatedAmount:
                        updatedAmount !== null
                            ? parseInt(updatedAmount.toFixed(0))
                            : null,
                });
            }
        } else if (
            (companyCustomerCountry.length > 0 ||
                companyCustomerPostalCode.length > 0 ||
                companyCustomerRegion.length > 0 ||
                companyName.length > 0 ||
                companyCustomerAddressLineOne.length > 0 ||
                companyCustomerAddressLineTwo.length > 0 ||
                companyCustomerCity.length > 0 ||
                companyCustomerCountry.length > 0 ||
                additionalNotes.length > 0 ||
                customerVATID.length > 0) &&
            piID !== null &&
            customerID !== undefined
        ) {
            postUpdatePaymentIntent({
                accessToken,
                cart: {
                    new_purchases: licensesJson,
                    upgrades: upgradeJson,
                },
                customer_id: customerID,
                metadata: {
                    additionalNotes: additionalNotes,
                    companyCustomerCountry: companyCustomerCountry,
                    companyCustomerPostalCode: companyCustomerPostalCode,
                    companyCustomerRegion: companyCustomerRegion,
                    companyName: companyName,
                    companyaddressOne: companyCustomerAddressLineOne,
                    companyaddressTwo: companyCustomerAddressLineTwo,
                    companycity: companyCustomerCity,
                    customerVATID: customerVATID,
                },
                paymentIntentID: piID,
                purchaseApiUrl,
                updatedAmount: cartFromAPI.cart.pricing.final_price,
            });
        } else {
            jurisdictionForStripeStorage = null;
        }
        setTaxDetermined(true);
        setLoading(false);
    };

   

    return (
        <BillingAddressForm 
            onSubmit={determineTaxAndUpdatePaymentIntentWithOptionalCompanyInfo}
            style={{ display: props.taxDetermined ? 'none' : 'block' }}
        >
            {props.serverError.length > 0 && <Error>{props.serverError}</Error>}
            {enforceBillingForm.show && (
                <Error>
                    {enforceBillingForm.errorMessage.length > 0
                        ? enforceBillingForm.errorMessage
                        : 'Make sure you fill out the entire billing form.'}
                </Error>
            )}
            <h3>Billing Details</h3>
            <FormContainer>
                <InputRowWrap>
                    <label htmlFor='name'>Name on card:</label>
                    <InputField
                        type='text'
                        value={customerNameOnCard}
                        id='name'
                        onChange={(e): void => setNameOnCard(e.target.value)}
                    />
                </InputRowWrap>
                <InputRowWrap>
                    <label htmlFor='address1'>Address:</label>
                    <InputField
                        type='text'
                        value={customerAddressLineOne}
                        id='address1'
                        onChange={(e): void =>
                            setAddressLineOne(e.target.value)
                        }
                    />
                    <InputField
                        type='text'
                        value={customerAddressLineTwo}
                        id='address2'
                        onChange={(e): void =>
                            setAddressLineTwo(e.target.value)
                        }
                    />
                </InputRowWrap>
                <Grouping>
                    <InputRowWrap>
                        <label htmlFor='country'>Country:</label>
                        <CountryDropdown
                            id='country'
                            value={customerCountry}
                            valueType='short'
                            onChange={(e): void => setCountry(e)}
                        />
                    </InputRowWrap>

                    <InputRowWrap>
                        <label htmlFor='region'>Region:</label>
                        <RegionDropdown
                            country={customerCountry}
                            value={customerRegion}
                            id='region'
                            valueType='short'
                            countryValueType='short'
                            onChange={(e): void => setRegion(e)}
                        />
                    </InputRowWrap>
                </Grouping>
                <InputRowWrap>
                    <Row>
                        <div style={{ margin: '0', width: '49%' }}>
                            <label htmlFor='city'>City:</label>
                            <InputField
                                type='text'
                                id='city'
                                value={customerCity}
                                onChange={(e): void => setCity(e.target.value)}
                            />
                        </div>
                        <div style={{ margin: '0', width: '49%' }}>
                            <label htmlFor='customerPostalCode'>
                                Postal code:
                            </label>
                            <InputField
                                type='text'
                                id='customerPostalCode'
                                value={customerPostalCode}
                                onChange={(e): void =>
                                    setCustomerPostalCode(e.target.value)
                                }
                            />
                        </div>
                    </Row>
                </InputRowWrap>
                <OptionalBusinessSection>
                    <div>
                        <span
                            onClick={(): void =>
                                setAddCompanyNameOrVAT(!addCompanyNameOrVAT)
                            }
                        >
                            Add company name, VAT ID, or address?
                        </span>
                        <p>
                            These will be added to an optional invoice you can
                            request.
                        </p>
                    </div>
                    {addCompanyNameOrVAT && (
                        <div>
                            <label htmlFor='customerVATID'>VAT ID:</label>
                            <InputField
                                type='text'
                                id='customerVATID'
                                value={customerVATID}
                                onChange={(e): void =>
                                    setCustomerVATID(e.target.value)
                                }
                            />
                            <label htmlFor='companyName'>Company name:</label>
                            <InputField
                                type='text'
                                id='companyName'
                                value={companyName}
                                onChange={(e): void =>
                                    setCompanyName(e.target.value)
                                }
                            />
                            <p
                                onClick={(): void =>
                                    setAddCompanyAddress(!addCompanyAddress)
                                }
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    textDecoration: 'underline',
                                }}
                            >
                                Company address?
                            </p>
                            {addCompanyAddress && (
                                <CompanyAddressSection>
                                    <InputRowWrap>
                                        <label htmlFor='companyaddress1'>
                                            Address:
                                        </label>
                                        <InputField
                                            type='text'
                                            value={
                                                companyCustomerAddressLineOne
                                            }
                                            id='address1'
                                            onChange={(e): void =>
                                                setCompanyAddressLineOne(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <InputField
                                            type='text'
                                            value={
                                                companyCustomerAddressLineTwo
                                            }
                                            id='address2'
                                            onChange={(e): void =>
                                                setCompanyAddressLineTwo(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </InputRowWrap>
                                    <Grouping>
                                        <InputRowWrap>
                                            <label htmlFor='companycountry'>
                                                Country:
                                            </label>
                                            <CountryDropdown
                                                id='companycountry'
                                                value={companyCustomerCountry}
                                                valueType='short'
                                                onChange={(e): void =>
                                                    setCompanyCountry(e)
                                                }
                                            />
                                        </InputRowWrap>
                                        <InputRowWrap>
                                            <label htmlFor='companyregion'>
                                                Region:
                                            </label>
                                            <RegionDropdown
                                                country={companyCustomerCountry}
                                                value={companyCustomerRegion}
                                                id='companyregion'
                                                valueType='short'
                                                countryValueType='short'
                                                onChange={(e): void =>
                                                    setCompanyRegion(e)
                                                }
                                            />
                                        </InputRowWrap>
                                    </Grouping>
                                    <InputRowWrap>
                                        <Row>
                                            <div
                                                style={{
                                                    margin: '0',
                                                    width: '49%',
                                                }}
                                            >
                                                <label htmlFor='companycity'>
                                                    City:
                                                </label>
                                                <InputField
                                                    type='text'
                                                    id='companycity'
                                                    value={companyCustomerCity}
                                                    onChange={(e): void =>
                                                        setCompanyCity(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    margin: '0',
                                                    width: '49%',
                                                }}
                                            >
                                                <label htmlFor='companyCustomerPostalCode'>
                                                    Postal code:
                                                </label>
                                                <InputField
                                                    type='text'
                                                    id='companyCustomerPostalCode'
                                                    value={
                                                        companyCustomerPostalCode
                                                    }
                                                    onChange={(e): void =>
                                                        setCompanyCustomerPostalCode(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Row>
                                    </InputRowWrap>
                                </CompanyAddressSection>
                            )}
                            <label htmlFor='additionalNotes'>
                                Invoice notes for your records:
                            </label>
                            <InputField
                                type='text'
                                id='additionalNotes'
                                value={additionalNotes}
                                onChange={(e): void =>
                                    setAdditionalNotes(e.target.value)
                                }
                            />
                        </div>
                    )}
                </OptionalBusinessSection>
                {cardError && <Error>{cardErrorMessage}</Error>}
                {addressError && <Error>There is an error with the inputed address.</Error>}
                <NewButton
                    id='ContinueWithCheckoutButton'
                    center
                    minWidth='300px'
                    showLoading={loading}
                >
                    Continue
                </NewButton>
            </FormContainer>
        </BillingAddressForm>
    )
}

export default BillingDetails;
