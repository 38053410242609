import { store } from '../index';
import { GET_SUBSCRIPTIONS_EP } from '../constants/PurchaseEndpoints';
import { setInitialSubscriptions } from '../actions/subscriptions';
import { Action } from '../actions/types'
interface GetSubscriptionsParams {
    accessToken: string;
    customerID: string;
    purchaseApiUrl: string;
    inactive?: boolean;
}

const getSubscriptions = async ({
    accessToken,
    customerID,
    inactive,
    purchaseApiUrl,
}: GetSubscriptionsParams): Promise<Action | boolean> => {
    const query = inactive !== undefined && inactive ? '?status=canceled' : '';
    
    if (!customerID) {
        console.log('Unable to pull subscriptions: customerID is not defined')
        return await new Promise((reject) => reject(true));
    }
    try {
        const subscriptions = await fetch(`${purchaseApiUrl}${GET_SUBSCRIPTIONS_EP({customer_id: customerID})}${query}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        });
    
        const response = await subscriptions.json();
    
        if (response) {
            // console.log('Successfully pulled subscriptions')
            return store.dispatch(setInitialSubscriptions({
                subscriptions: response
            }))
        } else {
            console.log('Did not pull subscriptions: ')
        }
    } catch(error) {
        console.log('There was a fetching error pulling subscriptions: ', error)
    }
};

export default getSubscriptions;
