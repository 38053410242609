const API_1_1 = '/api/1.1';

// HTTP Method: POST
export const MANAGED_TEAM_LICENSES_EP = `${API_1_1}/managed_team_licenses/`;

// HTTP Method: POST
export const PURCHASES_EP = `${API_1_1}/purchases/`;

// HTTP Method: POST
export const REGISTER_EP = `${API_1_1}/register/`;

// HTTP Method: POST
export const SIGNIN_EP = `${API_1_1}/signin/`;

// HTTP Method: GET
export const SIGNOUT_EP = `${API_1_1}/signout/`;

// HTTP Method: POST
export const TOKEN_REFRESH_EP = `${API_1_1}/refresh/`;

// HTTP Method: POST
export const USER_EP = `${API_1_1}/user/`;
