import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import StoreFrontContainer from './StoreContainer';
import { fakeClearCart } from '../actions/cart';
import getEligibleUpgrades from '../utils/getUpgradeItems';

import * as vars from "../styles/variables";

const Header = styled.h1`
    text-align: center;
    margin: 30px auto;
    line-height: 1.3em;
    color: ${vars.whiteColor};
`;

const ProductInformationAndHelp = styled.div`
    margin: 20px auto;
    max-width: 700px;
    color: ${vars.whiteColor};

    > p {
        > a {
            color: ${vars.whiteColor};
        }
    }
`;

const ProductList = styled.ul`
    font-size: 20px;

    li {
        list-style-position: inside;
        width: 450px;
        text-align: left;
    }
`;

interface SuccessProps {
    licenses?: Array<OmniStore.LicenseCartItem>;
    subscriptions?: Array<OmniStore.SubscriptionCartItem>;
    upgrades?: Array<OmniStore.LicenseUpgradeCartItem>;
}

const Success: React.FC<SuccessProps> = (props) => {
    const signedInAccount = useSelector((state: OmniStore.GlobalReduxState) => ({
        account: state.accounts.expandedUserInfo,
    }));

    const intl = useIntl();
    const dispatch = useDispatch();
    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);

    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);

    React.useEffect(() => {
        localStorage.removeItem('cart');
        dispatch(fakeClearCart());
        getEligibleUpgrades({accessToken, purchaseApiUrl});
    }, [dispatch]);

    return (
        <StoreFrontContainer>
            <Header>
                <FormattedMessage id='thankYouExclamation' />
                <ProductList>
                    { props.licenses && props.licenses.map((product) => {
                            return (
                                <li key={product.dateAddedToCart}>
                                    {product.standardOrPro === 'PRO'
                                        ? product.displayName.includes('Plan')
                                            ? product.displayName.replace(
                                                  'OmniPlan 4',
                                                  'OmniPlan 4 Pro'
                                              )
                                            : product.displayName.replace(
                                                  'Licenses for Mac',
                                                  'Pro for Mac Licenses'
                                              )
                                        : product.displayName.includes(
                                              'OmniOutliner 5'
                                          )
                                        ? 'OmniOutliner 5 Essentials for Mac'
                                        : product.displayName}
                                </li>
                            );
                        })}
                    { props.upgrades && props.upgrades.map((product) => {
                        return (
                            <li key={product.dateAddedToCart}>
                                { product.standardOrProUpgrade === 'PRO'
                                    ? product.displayName.includes('Plan')
                                        ? product.displayName.replace(
                                              'OmniPlan 4',
                                              'OmniPlan 4 Pro'
                                          )
                                        : product.displayName.replace(
                                              'for Mac',
                                              'Pro for Mac'
                                          )
                                    : product.displayName.includes(
                                          'OmniOutliner 5'
                                      )
                                    ? 'OmniOutliner 5 Essentials for Mac'
                                    : product.displayName
                                } License Upgrade
                            </li>
                        );
                    })}
                    { props.subscriptions && props.subscriptions.map((product) => {
                        return (
                            <li key={product.dateAddedToCart}>
                                {product.displayName}
                            </li>
                        );
                    })}
                </ProductList>
            </Header>
            <ProductInformationAndHelp>
                <p>
                    <FormattedMessage
                        id='storePurchaseSuccessPage'
                        values={{ 
                            OmniAccountsLink: (
                                <a href={accountsApiUrl}>
                                    {intl.formatMessage({
                                        id: 'omniAccountsLinkText',
                                    })}
                                </a>
                            ),
                            userEmail: signedInAccount.account?.email }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id='instructionsOnPurchase'
                        values={{ userEmail: signedInAccount.account?.email }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id='contactUsPostPurchase'
                        values={{
                            EmailSupport: (
                                <a href='https://support.omnigroup.com'>
                                    {intl.formatMessage({
                                        id: 'contactUsShortString',
                                    })}
                                </a>
                            ),
                        }}
                    />
                </p>
            </ProductInformationAndHelp>
        </StoreFrontContainer>
    );
};

export default Success;
