import { store } from '../index';
import { TOKEN_REFRESH_EP } from '../constants/AccountsEndpoints';
import { setAccountsInfo } from '../actions/accounts';
import { Action } from '../actions/types';

interface TokenRefreshParams {
    accountsApiUrl: string;
    refreshToken: string;
}

const tokenRefresh = async ({
    accountsApiUrl,
    refreshToken,
}: TokenRefreshParams): Promise<Action> => {
    const refreshParams: URLSearchParams = new URLSearchParams();
    refreshParams.set('refresh_token', refreshToken);

    try {
        const tokenNeedsRefresh = await fetch(`${accountsApiUrl}${TOKEN_REFRESH_EP}`, {
            body: refreshParams,
            headers: {
                Accept: 'application/json',
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        });
    
        const response = await tokenNeedsRefresh.json();
        
        if (!response.errors) {
            return store.dispatch(
                setAccountsInfo({
                    accountInfo: {
                        token: response.token,
                        userInfo: response.userinfo,
                    },
                    accountsError: undefined,
                })
            );
        }
        if (response.errors) {
            response.errors.map((err) => {
                return store.dispatch(setAccountsInfo({
                    accountInfo: undefined,
                    accountsError: {
                        code: err.code,
                        message: err.message
                    }
                }))
            })
        }
    } catch (error) {
        console.log('There was a fetching error while trying to refresh your `accessToken`: ', error)
    }
};

export default tokenRefresh;
