import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import NewButton from '../components/NewButton';
import * as vars from '../styles/variables';

import { toggleSignInForm } from '../actions/accounts';

interface StyleProps {
    backgroundColor?: string;
    color?: string;
    offtw?: boolean;
    product?: string;
}

const DiscountContainer = styled.div<StyleProps>`
    background-color: ${(props) => props.backgroundColor};
    border: 1px solid ${(props) => props.color};
    border-radius: 5px;
    justify-content: center;
    padding: 24px;

    > h1 {
        background-color: ${(props) => props.color};
        border-radius: 3px 3px 0 0;
        text-align: center;
        font-weight: 500;
        margin: 0;
        font-size: 24px;
        margin: 0.5em 0 1em;
        line-height: 1;
        color: ${(props) => props.product === 'omnifocus' ? vars.whiteColor : vars.blackColor};
    }
    > p {
        line-height: 1.2;
        font-size: 18px;
        margin: 0;
    }

    @media screen and (min-width: 701px) {
        padding: 0;

        > h1 {
            font-size: 20px;
            padding: 20px;
            margin: 0;
        }

        > p {
            padding: 25px;
            font-size: 17px;
        }

        > div {
            padding: 10px;
        }
    }

    @media screen and (max-width: 700px) {
        max-width: 300px;
        padding: 0;

        > h1 {
            padding: 10px;
            margin: 0;
        }

        > div {
            padding: 10px;
        }

        > p, a {
            padding: 15px;
        }
    }
`;

const BottomPortion = styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    #DiscountSignInButton {
        margin: 2em auto 1em;
        width: 340px;
    }
    > div {
        font-weight: 500;
        text-align: center; 
        /* color: ${vars.whiteColor}; */
    }
    > a {
        text-align: center;
        /* color: ${vars.whiteColor}; */
    }
    #register-link {
        font-size: 14px;
        text-align: start;
        padding-top: 0.5em;
    }

    @media screen and (max-width: 750px) {
        #DiscountSignInButton {
            width: auto;
        }
    }
`;

const SignedInBlurb = styled.div`
    font-size: 16px;
    padding: 1em;
`;

const Border = styled.div<StyleProps>`
    border-top: 1px solid ${vars.transparentWhite};
`;
 
interface DiscountBlockProps {
    backgroundColor: string;
    blockHeader?: string;
    color: string;
    className?: string;
    description: string;
    offtw?: boolean;
    product: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    signIn: string;
}

const DiscountBlock: React.FC<DiscountBlockProps> = (props: DiscountBlockProps) => {
    const dispatch = useDispatch();
    const has_auth = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.has_auth);
    const showSigninForm = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.showSigninForm);

    const buttonColor = props.product === 'omnifocus' ? vars.purple 
        : props.product === 'omnigraffle' ? vars.green 
        : props.product === 'omnioutliner' ? vars.orange
        : vars.lightYellow; //omniplan color

    return (
        <DiscountContainer 
            backgroundColor={props.backgroundColor}
            color={props.color} 
            product={props.product}
        >
            <h1>{props.blockHeader}</h1>
            <p>
                {has_auth ? 
                    <FormattedMessage 
                        id="upgradeDiscountDescriptionSignedIn"
                        values={{
                            OmniAccount: (
                                <a href={'https://accounts.omnigroup.com'}>Omni Account</a>
                            )
                        }}
                    /> 
                    : props.description 
                }
            </p>
            <BottomPortion product={props.product}>
                {has_auth ? (
                    <>
                        <SignedInBlurb>Any eligible discounts will be shown in the cart.</SignedInBlurb>
                        <Border product={props.product}></Border>
                        <a id='register-link' href={'https://store.omnigroup.com/register-license'}>Need to register a license key?</a>
                    </>
                ) : (
                    <>
                        <NewButton
                            id="DiscountSignInButton"
                            color={buttonColor}
                            omnifocus={props.product === 'omnifocus' ? true : false }
                            onClick={() => dispatch(toggleSignInForm({display: !showSigninForm}))}
                        >
                            {props.signIn}
                        </NewButton>
                        <a href="https://accounts.omnigroup.com/register">Create an Omni Account</a>
                    </>
                )}
            </BottomPortion> 
        </DiscountContainer>
    )
};

export default DiscountBlock;