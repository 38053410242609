import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import * as vars from '../styles/variables';

interface StyledProps {
    collapsed?: boolean;
    hidden?: boolean;
    highlighted?: boolean;
    color?: string;
}

const AppsInBlockForm = styled.div<StyledProps>`
    display: ${(props): string => (props.hidden ? 'none' : 'flex')};
    justify-content: center;
    flex-flow: wrap;
    flex-direction: row;
    margin: 0;
    border-bottom: ${(props): string =>
        props.collapsed ? '0' : `1px solid ${props.color}`};

    > a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    @media (max-width: 420px) {
        justify-content: space-around;
    }
`;

const AppModule = styled.div<StyledProps>`
    width: ${(props): string => (props.collapsed ? 'auto' : '200px')};
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    color: ${vars.whiteColor};
    text-align: center;
    justify-content: ${(props): string =>
        props.collapsed ? 'center' : 'center'};

    border-bottom: ${(props): string =>
        props.highlighted
            ? `3px solid ${props.color}`
            : '0 solid #000'};

    h2 {
        color: ${vars.whiteColor};
        font-size: ${(props): string => (props.collapsed ? '24px' : '20px')};
        font-weight: ${(props): string => (props.highlighted ? '500' : '300')};
        margin: 10px 0;
        font-size: 23px;
    }

    > button {
        width: 72px;
        height: 32px;
        font-size: 17px;
        margin: auto;
        border: none;
        border-radius: 20px;
        background-color: ${vars.blue};
        color: ${vars.blackColor};

        &:hover {
            cursor: pointer;
        }
    }
    

    @media (max-width: 420px) {
        width: 150px;
    }
`;

const AppTagline = styled.p<StyledProps>`
    display: ${(props): string => (props.collapsed ? 'block' : 'none')};
    color: ${vars.whiteColor};
    height: 80px;

    @media (max-width: 420px) {
        font-size: 0.9em;
        line-height: 1.2em;
    }

    @media (max-width: 380px) {
        font-size: 0.8em;
    }
`;

const IconImage = styled.img<StyledProps>`
    width: 150px;
    margin: 0 auto;
    display: ${(props): string => (props.collapsed ? 'block' : 'none')};

    &:hover {
        -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
    }
    @media (max-width: 420px) {
        width: 80px;
    }
`;

interface StoreInventoryProps {
    collapsed: boolean;
    hidden?: boolean;
    color: string;
}

const StoreInventory: React.FC<StoreInventoryProps> = (props) => {
    const intl = useIntl();

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <AppsInBlockForm collapsed={props.collapsed} hidden={props.hidden} color={props.color}>
            <Link to='/omnifocus/'>
                <AppModule highlighted={location.pathname === '/omnifocus/'} color={props.color}>
                    <h2>OmniFocus</h2>
                    <IconImage
                        collapsed={props.collapsed}
                        alt={intl.formatMessage({
                            id: 'OmniFocusIconAltText',
                        })}
                        src={'/icons/omnifocus.svg'}
                    />
                    <AppTagline collapsed={props.collapsed}>
                        <FormattedMessage id='shortAppDescriptionOmniFocus' />
                    </AppTagline>
                    {location.pathname === '/' && (
                        <button 
                            aria-label="Buy OmniFocus"
                            onClick={() => navigate('/omnifocus')}
                        >
                            Buy
                        </button>
                    )}
                </AppModule>
            </Link>
            <Link to='/omnigraffle/'>
                <AppModule highlighted={location.pathname === '/omnigraffle/'} color={props.color}>
                    <h2>OmniGraffle</h2>
                    <IconImage
                        collapsed={props.collapsed}
                        alt={intl.formatMessage({
                            id: 'OmniGraffleIconAltText',
                        })}
                        src={'/icons/omnigraffle.svg'}
                    />
                    <AppTagline collapsed={props.collapsed}>
                        <FormattedMessage id='shortAppDescriptionOmniGraffle' />
                    </AppTagline>
                    {location.pathname === '/' && (
                        <button 
                            aria-label="Buy OmniGraffle"
                            onClick={() => navigate('/omnigraffle')}
                        >
                            Buy
                        </button>
                    )}
                </AppModule>
            </Link>
            <Link to='/omnioutliner/'>
                <AppModule highlighted={location.pathname === '/omnioutliner/'} color={props.color}>
                    <h2>OmniOutliner</h2>
                    <IconImage
                        collapsed={props.collapsed}
                        alt={intl.formatMessage({
                            id: 'OmniOutlinerIconAltText',
                        })}
                        src={'/icons/omnioutliner.svg'}
                    />
                    <AppTagline collapsed={props.collapsed}>
                        <FormattedMessage id='shortAppDescriptionOmniOutliner' />
                    </AppTagline>
                    {location.pathname === '/' && (
                        <button 
                            aria-label="Buy OmniOutliner"
                            onClick={() => navigate('/omnioutliner')}
                        >
                            Buy
                        </button>
                    )}
                </AppModule>
            </Link>
            <Link to='/omniplan/'>
                <AppModule highlighted={location.pathname === '/omniplan/'} color={props.color}>
                    <h2>OmniPlan</h2>
                    <IconImage
                        collapsed={props.collapsed}
                        alt={intl.formatMessage({
                            id: 'OmniPlanIconAltText',
                        })}
                        src={'/icons/omniplan.svg'}
                    />
                    <AppTagline collapsed={props.collapsed}>
                        <FormattedMessage id='shortAppDescriptionOmniPlan' />
                    </AppTagline>
                    {location.pathname === '/' && (
                        <button 
                            aria-label="Buy OmniPlan"
                            onClick={() => navigate('/omniplan')}
                        >
                            Buy
                        </button>
                    )}
                </AppModule>
            </Link>
        </AppsInBlockForm>
    );
};

export default StoreInventory;
