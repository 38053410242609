import { ActionType, Action } from './types';

export const setAccountsInfo = ({ accountInfo, accountsError}): Action => {
    return {
        accountInfo,
        accountsError,
        type: ActionType.REFRESH_ACCESS_TOKEN,
    };
};

export const setExpandedUserInfo = ({ userinfo }): Action => {
    return {
        type: ActionType.SET_EXPANDED_USER_INFO,
        userinfo,
    };
};

export const setManagedTeamLicensesInfo = ({ managedTeamLicenses }): Action => {
    return {
        managedTeamLicenses,
        type: ActionType.SET_MANAGED_TEAM_LICENSES_INFO,
    };
};

export const setAuthToFalse = (): Action => {
    return {
        type: ActionType.SET_AUTH_TO_FALSE,
    }
}

export const signInToAccounts = ({ accountInfo, accountsError }): Action => {
    return {
        accountInfo,
        accountsError,
        type: ActionType.SIGN_IN_TO_ACCOUNTS,
    };
};

export const signOutOfAccounts = (): Action => {
    return {
        type: ActionType.SIGN_OUT_OF_ACCOUNTS,
    };
};

export const toggleSignInForm = ({ display }): Action => {
    return {
        display,
        type: ActionType.TOGGLE_SIGNIN_FORM,
    }
}

export const setPurchasesData = ({ purchasesData }): Action => {
    return {
        purchasesData,
        type: ActionType.SET_PURCHASES_DATA,
    }
}