import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { List } from 'react-content-loader';

import { SideBySide } from './Button';
import NewButton from './NewButton';

import { toggleSignInForm } from '../actions/accounts';
import * as vars from '../styles/variables';

const SubTotal = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 20px;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 900px) {
        width: 100%;
        text-align: center;
        align-self: flex-start;
        border-left: 0;
        padding-left: 0;
        margin: 20px auto;
    }
`;

const SubSpan = styled.span`
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 20px;
`;

const ActualSubtotal = styled.div`
    font-size: 20px;
    font-weight: 400;
`;

const SummaryBeforeCheckout = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(105, 201, 237, 1);
    padding-left: 40px;
    margin-top: 20px;
    width: 30%;
    color: ${vars.blackColor};

    @media (max-width: 900px) {
        width: 100%;
        text-align: center;
        align-self: flex-start;
        border-left: 0;
        padding-left: 0;
        margin: 20px auto;
        max-width: unset;
    }
`;

interface CartSummaryProps {
    cartSummaryFigures: OmniStore.CartFromAPI;
}

const CartSummary: React.FC<CartSummaryProps> = (props) => {
    const dispatch = useDispatch();

    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => state.cart.cart);
    const showSignInForm = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.showSigninForm);
    const signedInStatus = useSelector((state: OmniStore.GlobalReduxState) => ({
        account: state.accounts.expandedUserInfo,
    }));

    const [loading, setLoading] = useState(true);
    const [licenseCartEstimate, setLicenseCartEstimate] = useState(0);
    const [subscriptionCartEstimate, setSubscriptionCartEstimate] = useState(0);
    
    useEffect(() => {
        if (sessionStorage.getItem('setupIntentClientSecret')) {
            sessionStorage.removeItem('setupIntentClientSecret');
            sessionStorage.removeItem('setupIntentID');
        }
    });

    useEffect(() => {
        let newLicenseTotalCostEstimate = 0;

        cartItems.licenses.map((item: OmniStore.LicenseCartItem) => {
            if (item) {
                newLicenseTotalCostEstimate += 
                    (item.price * item.quantity) / 100;
                    return true;
            }
            return false;
        });
        setLicenseCartEstimate(newLicenseTotalCostEstimate);
    }, [cartItems.licenses]);

    useEffect(() => {
        let newSubscriptionTotalCostEstimate = 0;
        cartItems.subscriptions.map((item: OmniStore.SubscriptionCartItem) => {
            if (item.payInterval === 'MONTHLY') {
                newSubscriptionTotalCostEstimate +=
                    (item.monthlyPrice * item.quantity) / 100;
                return true;
            } else if (item.payInterval === 'YEARLY') {
                newSubscriptionTotalCostEstimate +=
                    (item.yearlyPrice * item.quantity) / 100;
                return true;
            }
            return false;
        });
        setSubscriptionCartEstimate(newSubscriptionTotalCostEstimate);
        setLoading(false);
    }, [cartItems.subscriptions]);

    if (loading) {
        return (
            <SummaryBeforeCheckout>
                <List />
            </SummaryBeforeCheckout>
        );
    }

    return (
        <SummaryBeforeCheckout>
            {signedInStatus.account !== undefined ? (
                <React.Fragment>
                    {cartItems.subscriptions.length > 0 && (
                        <SubTotal
                            style={{
                                borderBottom: '1px solid #000',
                                marginBottom: '20px',
                                paddingBottom: '20px',
                            }}
                        >
                            <span>
                                <FormattedMessage id='subscriptionSubtotalTitle' />
                            </span>
                            <ActualSubtotal>
                                <FormattedNumber
                                    style={`currency`}
                                    currency='USD'
                                    value={subscriptionCartEstimate}
                                />
                            </ActualSubtotal>
                        </SubTotal>
                    )}
                    {props.cartSummaryFigures.message === undefined && (
                        <React.Fragment>
                            {cartItems.upgrades.length > 0 && (
                                <SubTotal>
                                    <span>List Price</span>
                                    <ActualSubtotal>
                                        <FormattedNumber
                                            style={`currency`}
                                            currency='USD'
                                            value={(props.cartSummaryFigures.pricing.cart_total + 
                                                props.cartSummaryFigures.pricing.upgrade_discount)/100
                                            }
                                        />
                                    </ActualSubtotal>
                                </SubTotal>
                            )}
                            {props.cartSummaryFigures.pricing.upgrade_discount > 0 &&
                                cartItems.upgrades.length > 0 && (
                                    <SubTotal>
                                        <span>Upgrade Discount</span>
                                        <ActualSubtotal>
                                            {'-'}
                                            <FormattedNumber
                                                style={`currency`}
                                                currency='USD'
                                                value={
                                                    props.cartSummaryFigures
                                                        .pricing
                                                        .upgrade_discount / 100
                                                }
                                            />
                                        </ActualSubtotal>
                                    </SubTotal>
                                )
                            }
                            {props.cartSummaryFigures.pricing
                                .bundle_discount_amount > 0 &&
                                (cartItems.licenses.length > 0 || cartItems.upgrades.length) && (
                                    <React.Fragment>
                                        <SubTotal
                                            style={{
                                                marginBottom: '0',
                                            }}
                                        >
                                            <span>Quantity Discount</span>
                                            <ActualSubtotal>
                                                {'-'}
                                                <FormattedNumber
                                                    style={`currency`}
                                                    currency='USD'
                                                    value={
                                                        props.cartSummaryFigures
                                                            .pricing
                                                            .bundle_discount_amount /
                                                        100
                                                    }
                                                />
                                            </ActualSubtotal>
                                        </SubTotal>
                                        <SubSpan>
                                            Available for Licenses only
                                        </SubSpan>
                                    </React.Fragment>
                                )}
                            {(cartItems.licenses.length > 0 || cartItems.upgrades.length > 0 ) && (
                                <SubTotal>
                                    <span>
                                        <FormattedMessage id='licenseSubtotalTitle' />
                                    </span>
                                    <ActualSubtotal>
                                        <FormattedNumber
                                            style={`currency`}
                                            currency='USD'
                                            value={
                                                props.cartSummaryFigures.pricing
                                                    .final_price / 100
                                            }
                                        />
                                    </ActualSubtotal>
                                </SubTotal>
                            )}
                        </React.Fragment>
                    )}
                    <SideBySide>
                        <Link
                            to={
                                signedInStatus.account !== undefined && '/checkout'
                                    // : '/signin'
                            }
                            onClick={() => signedInStatus.account === undefined && dispatch(toggleSignInForm({display: !showSignInForm}))}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <NewButton
                                id='CheckoutButton'
                                center
                                minWidth='250px'
                            >
                                <FormattedMessage id='checkOut' />
                            </NewButton>
                        </Link>
                        <Link
                            to={'/'}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <NewButton
                                id='ContinueShoppingButton'
                                center
                                lightGray
                                minWidth='250px'
                            >
                                <FormattedMessage id='continueShopping' />
                            </NewButton>
                        </Link>
                    </SideBySide>
                </React.Fragment>
            ) : (
                <>
                    {cartItems.subscriptions.length > 0 && (
                        <SubTotal style={{ marginBottom: '20px' }}>
                            <span>
                                <FormattedMessage id='subscriptionSubtotalTitle' />
                            </span>
                            <ActualSubtotal>
                                <FormattedNumber
                                    style={`currency`}
                                    currency='USD'
                                    value={subscriptionCartEstimate}
                                />
                            </ActualSubtotal>
                        </SubTotal>
                    )}
                    {(cartItems.licenses.length > 0 || cartItems.upgrades.length > 0) && (
                        <SubTotal>
                            <span>
                                <FormattedMessage id='licenseSubtotalTitle' />
                            </span>
                            <ActualSubtotal>
                                <FormattedNumber
                                    style={`currency`}
                                    currency='USD'
                                    value={licenseCartEstimate}
                                />
                            </ActualSubtotal>
                        </SubTotal>
                    )}
                    <p>
                        These are estimates.{' '}
                        <span
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onClick={() =>
                                dispatch(
                                    toggleSignInForm({
                                        display: !showSignInForm,
                                    })
                                )
                            }
                        >
                            Sign in
                        </span>{' '}
                        for quantity discounts and upgrade pricing.
                    </p>
                    <SideBySide>
                        <Link
                            to={signedInStatus.account !== undefined && '/checkout'}
                            onClick={() => signedInStatus.account === undefined && dispatch(toggleSignInForm({display: !showSignInForm}))}
                            style={{textDecoration: 'none'}}
                        >
                            <NewButton
                                id='CheckoutButtonOnCartPage'
                                center
                                minWidth='250px'
                            >
                                <FormattedMessage id='checkOut' />
                            </NewButton>
                        </Link>
                        <Link
                            to={'/'}
                            style={{ textDecoration: 'none' }}
                        >
                            <NewButton
                                id='ContinueShoppingOnCartSummary'
                                center
                                lightGray
                                minWidth='250px'
                            >
                                <FormattedMessage id='continueShopping' />
                            </NewButton>
                        </Link>
                    </SideBySide>
                </>
            )}
        </SummaryBeforeCheckout>
    );
};

export default CartSummary;
