import { ActionType, Action } from '../actions/types';

const initialState: OmniStore.GlobalEligibleLicenseUpgrades = {
};

const upgrades = (state = initialState, action: Action): OmniStore.GlobalEligibleLicenseUpgrades => {
    switch (action.type) {
        case ActionType.SET_ELIGIBLE_UPGRADES: {
            const eligible_upgrades = action.upgrades;
            const upgrades_available = eligible_upgrades.upgrades_available;
            const omni_products = [ 'omnifocus', 'omnioutliner', 'omniplan', 'omnigraffle'];
            const newState = {};

            if (upgrades_available) {
                for (const product in upgrades_available) {
                    omni_products.forEach((item) => {
                        const product_lowerCase = product.toLowerCase();
                        if (product_lowerCase.includes(item)) {
                            const newUpgrade = { [product]: upgrades_available[product] };
                            const product_upgrade = newState[item];

                            if (!product_upgrade) {
                                newState[item] = [ newUpgrade];
                            } else {
                                newState[item] = [...product_upgrade, newUpgrade]
                            }
                        }
                    })
                }
            }

            return {
                ...newState
            };
        }
        default: 
            return state;
    }
}

export default upgrades;