import styled from 'styled-components/macro';

interface RowElementProps {
    alignEnd?: boolean;
    alignMiddle?: boolean;
    centered?: boolean;
    maxWidth?: string;
    sideMargins?: boolean;
}

export const RowElement = styled.div<RowElementProps>`
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: ${(props: RowElementProps): string =>
        props.alignEnd ? 'flex-end' : 'unset'};
    justify-content: ${(props: RowElementProps): string =>
        props.centered ? 'center' : 'unset'};
    align-items: ${(props: RowElementProps): string =>
        props.alignMiddle ? 'center' : 'unset'};
    margin: ${(props: RowElementProps): string =>
        props.sideMargins ? '0 auto' : 'unset'};
    flex-wrap: wrap;
    max-width: ${(props: RowElementProps): string =>
        props.maxWidth ? props.maxWidth : 'unset'};

    @media (max-width: 500px) {
        flex-direction: column-reverse;
        width: 100%;
        max-width: unset;
        margin: 0;
    }
`;
