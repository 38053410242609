import { CREATE_CUSTOMER_EP } from '../constants/PurchaseEndpoints';

interface PostCreateCustomerProps extends OmniStore.CommonPurchaseAPIProperties {
    email: string;
    omni_id: string;
    omni_username: string;
}

interface PostCreateCustomerResponse {
    customer_id?: string;
    message?: string;
}

const postCreateCustomer = async ({
    accessToken,
    email,
    purchaseApiUrl,
    omni_id,
    omni_username,
}: PostCreateCustomerProps): Promise<PostCreateCustomerResponse> => {
    return await new Promise((resolve, reject) => {
        fetch(`${purchaseApiUrl}${CREATE_CUSTOMER_EP}`, {
            body: JSON.stringify({
                email: email,
                omni_id: omni_id,
                omni_username: omni_username,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return Promise.resolve(response.json())
                    .then((res) => !res.errors ? resolve({customer_id: res.customer_id, message: res.message}) : reject(true))
            })
            .catch((err) => {
                console.log('err', err);
                return reject(err);
            });
    });
};

export default postCreateCustomer;
