import * as React from 'react';
import styled from 'styled-components/macro';

import * as vars from '../styles/variables';

type Page = 'CART' | 'ACCOUNT' | 'PAYMENT' | null;

interface CartBreadCrumbProps {
    page: Page;
}

const BreadCrumbContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 0;
`;

// eslint-disable-next-line
interface CrumbProps {
    bolder: boolean;
}

const Crumb = styled.span<CrumbProps>`
    font-size: 18px;
    margin: 0 15px 0 0;
    line-height: 1.1em;
    color: ${(props): string =>
        props.bolder ? vars.whiteColor : 'rgba(143, 142, 149, 1.00)'};

    &::after {
        margin-left: 7px;
        content: '❯';
    }

    &:last-child {
        &::after {
            content: unset;
        }
    }
`;

const CartBreadCrumbs: React.FC<CartBreadCrumbProps> = (
    props: CartBreadCrumbProps
) => {
    if (props.page === null) return null;
    return (
        <BreadCrumbContainer>
            <Crumb bolder={props.page === 'CART'}>Cart</Crumb>
            <Crumb bolder={props.page === 'ACCOUNT'}>Account</Crumb>
            <Crumb bolder={props.page === 'PAYMENT'}>Payment</Crumb>
        </BreadCrumbContainer>
    );
};

export default CartBreadCrumbs;
