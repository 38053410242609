export const productsDropdown = [
    'OmniFocus',
    'OmniGraffle',
    'OmniOutliner',
    'OmniPlan'
];

export const companyDropdownList = {
    'Blog': 'https://www.omnigroup.com/blog',
    'The Omni Show': 'https://theomnishow.omnigroup.com/',
    //eslint-disable-next-line
    'Newsletter': 'https://www.omnigroup.com/letters',
    'Your Privacy': 'https://www.omnigroup.com/privacy',
    //eslint-disable-next-line
    'About Us': 'https://www.omnigroup.com/about',
    //eslint-disable-next-line
    'Mission': 'https://www.omnigroup.com/mission',
    //eslint-disable-next-line
    'Merch': 'https://merch.omnigroup.com/'
};

export const helpDropdownList = {
    'Support': 'https://support.omnigroup.com/',
    //eslint-disable-next-line
    'Stenciltown': 'https://stenciltown.omnigroup.com/',
    'Videos': 'https://www.omnigroup.com/video',
    //eslint-disable-next-line
    'Inside OmniFocus': 'https://inside.omnifocus.com/',
    'Inside OmniGraffle': 'https://inside.omnigraffle.com/'
}