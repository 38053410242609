interface RegisterLicenseKeyProps {
    accessToken: string;
    accountsApiUrl: string;
    licenseKey: string;
    licenseOwner: string;
}

interface RegisterLicenseKeyResponse {
    status: 'success' | 'error';
    messages?: Array<{
        code: number, 
        message: string
    }>;
    message?: string;
}

const postRegisteredLicenseKey = async ({
    accessToken,
    accountsApiUrl,
    licenseKey,
    licenseOwner
}: RegisterLicenseKeyProps): Promise<RegisterLicenseKeyResponse> => {
    try {
        const seatData = await fetch(`${accountsApiUrl}/api/1.1/register/`, {
            body: JSON.stringify({
                license_key: licenseKey,
                license_owner: licenseOwner,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        });

        const response = await seatData.json();

        if (response) {
            if (response.status === 'success') {
                return {
                    message: 'Successful license key registration',
                    status: response.status,
                }
            } else if (response.status === 'error') {
                return {
                    messages: response.messages,
                    status: response.status,
                }
            }
            return response;
        }

    } catch (error) {
        console.log('error registering license key: ', error);
    } 
}

export default postRegisteredLicenseKey;