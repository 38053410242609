import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Portal from './Portal';
import NewButton from './NewButton';
import * as vars from '../styles/variables';

import { handleSignIn } from '../utils/handleSignInSignOut';
import { toggleSignInForm } from '../actions/accounts';


const SignInWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    color: ${vars.whiteColor};
    flex-direction: column;
`;

const CloseModal = styled.div`
    background-color: ${vars.blue};
    border-radius: 10px;
    max-width: 600px;
    width: 2em;

    > button {
        border: none;
        background: none;
        color: ${vars.whiteColor};
        font-size: 1em;
        cursor: pointer;
    }
`;

const SignInContainer = styled.div`
    background-color: ${vars.blue};
    padding: 2em;
    border-radius: 10px;
    max-width: 600px;
`;


const SignInTitle = styled.div`
    text-align: center;
    > img {
        height: 25px;
    }
    > div {
        font-size: 1.75em;
        padding: 0.5em;
    }
`;

const SignInParagraph = styled.p`
    text-align: center;
    line-height: 1.25;
    padding: 0 2em;
`;

const InputWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 4em;

    /* @media (min-width: 600px) {
        min-width: 500px;
    } */
    > label {
        padding: 1em 0 0;
        font-size: 1.1em;
        text-align: start;
    }

    > input {
        border: 1px solid ${vars.lightGray};
        border-radius: 4px;
        width: 100%;
    }
`;

const ErrorBox = styled.p`
    text-align: center;
    padding: 10px 20px;
    margin: 20px 4em;
    border: 1px solid ${vars.lightGray};
    background-color: ${vars.errorBoxLightYellow};
    border-radius: 8px;
    color: ${vars.blackColor};
`;

const SignUpForgotPasswordBox = styled.div`
    text-align: center;

    > a {
        color: ${vars.whiteColor};
}`;

interface LoginFormState {
    password: string;
    username: string;
}

interface SignInPortalProps {
    forceSignInFormOpen: boolean;
}

const SignInPortal: React.FC<SignInPortalProps> = (props: SignInPortalProps) => {
    const dispatch = useDispatch();

    const accounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);
    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const showSigninForm = useSelector((state:  OmniStore.GlobalReduxState) => state.accounts.showSigninForm);
    
    const [credentials, setCredentials] = useState<LoginFormState>({
        password: '',
        username: '',
    });
    const [errorLanguage, setErrorLanguage] = React.useState<OmniStore.AccountsErrorObject>({});
    const [loading, setLoading] = useState<boolean>(false);

    const closeModal = (): void => {
        setErrorLanguage({})
        dispatch(toggleSignInForm({ display: !showSigninForm }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        setErrorLanguage({});
        setLoading(true);

        const signIn = await handleSignIn({
            accountsApiUrl,
            password: credentials.password,
            username: credentials.username,
        });

        if (signIn) {
            setCredentials({ password: '', username: ''});
            setLoading(false);
            dispatch(toggleSignInForm({ display: true}))
        } else {
            console.log('There was an issue signing into accounts.');
            for (const [code, message] of Object.entries(accounts.accountsErrors)) {
                console.log(`code: ${code}`);
                console.log(`message: ${message}`);
            }
            setErrorLanguage(accounts.accountsErrors);
            setLoading(false);
        }
    };

    if (showSigninForm || props.forceSignInFormOpen) {
        return (
            <Portal>
                <SignInWrapper onClick={() => closeModal()}>
                    <SignInContainer onClick={(e) => e.stopPropagation()}>
                        <CloseModal>
                            <button onClick={() => closeModal()}>Close</button>
                        </CloseModal>
                        <SignInTitle>
                            <img
                               src='https://www.omnigroup.com/assets/img/2020/logo_white.png'
                               alt='Omni Group logo'
                            />
                            <div>Omni Account</div>
                        </SignInTitle>
                        <SignInParagraph>
                            <FormattedMessage id="signInFormParagraph"/>
                        </SignInParagraph>
                        <form method='post' onSubmit={(e) => handleSubmit(e)}>
                            <InputWrap>
                                <label htmlFor='username'>Account Name:</label>
                                <input
                                    autoComplete='username'
                                    name='username'
                                    onChange={(e): void =>
                                        setCredentials({
                                            password: credentials.password,
                                            username: e.target.value,
                                        })
                                    }
                                    spellCheck='false'
                                    type='text'
                                    autoFocus
                                />
                                <label htmlFor='password'>Password:</label>
                                <input
                                    autoComplete='current-password'
                                    name='password'
                                    onChange={(e): void =>
                                        setCredentials({
                                            password: e.target.value,
                                            username: credentials.username,
                                        })
                                    }
                                    spellCheck='false'
                                    type='password'
                                />
                            </InputWrap>
                            <NewButton
                                signIn={true}
                                buttonType='submit'
                                disabled={
                                    !credentials.username || !credentials.password
                                        ? true
                                        : false
                                }
                                id='SignInButton'
                                value='Sign In'
                                showLoading={loading}
                                minWidth='150px'
                            >
                                <FormattedMessage id='SignIn' />
                            </NewButton>
                        </form>
                        {Object.keys(errorLanguage).length > 0 &&
                            Object.entries(errorLanguage).map(([err, msg]) => {
                                return <ErrorBox key={err}>{msg}</ErrorBox>;
                        })}
                        <SignUpForgotPasswordBox>
                            <a href={accountsApiUrl + '/register'}>Sign up</a> | {" "}
                            <a href={accountsApiUrl + '/password-reset'}>Forgot Password?</a>
                        </SignUpForgotPasswordBox>
                    </SignInContainer>
                </SignInWrapper>
            </Portal>
        )
    }
    return null;
};

export default SignInPortal;