import { store } from '../index';
import { SIGNIN_EP, SIGNOUT_EP } from '../constants/AccountsEndpoints';
import { signInToAccounts, signOutOfAccounts } from '../actions/accounts';
import { Action } from '../actions/types';

interface HandleSignIn {
    accountsApiUrl: string;
    password: string;
    username: string;
}

export const handleSignIn = async ({
    accountsApiUrl,
    password,
    username,
}: HandleSignIn): Promise<Action> => {
    const loginParams: URLSearchParams = new URLSearchParams();

    loginParams.set('username', username);
    loginParams.set('password', password);

    const signIn = await fetch(`${accountsApiUrl}${SIGNIN_EP}`, {
        body: loginParams,
        headers: {
            Accept: 'application/json',
            'Content-Type':
                'application/x-www-form-urlencoded; charset=UTF-8',
        },
        method: 'POST',
        mode: 'cors',
    });

    const response = await signIn.json();

    if (!response.errors) {
        return store.dispatch(signInToAccounts({
            accountInfo: {
                token: response.token,
                userInfo: response.userinfo,
            },
            accountsError: undefined
        }))
    }
    if (response.errors) {
        response.errors.map((error) => {
            return store.dispatch(signInToAccounts({
                accountInfo: undefined,
                accountsError: {
                    code: error.code,
                    message: error.message,
                }
            }))
        })
    }
};

export const handleSignOut = async (accessToken: string, accountsApiUrl: string): Promise<void> => {
    try {
        await fetch(`${accountsApiUrl}${SIGNOUT_EP}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            method: 'GET',
            mode: 'cors',
        });
        store.dispatch(signOutOfAccounts());
        console.log('The user has signed out of accounts successfully.')
    } catch (error) {
        console.log('There was an issue signing out of accounts. The `access_token` may have been already been revoked.', error)
    }

};
