import { ActionType, Action } from '../actions/types';

const initialState: OmniStore.GlobalStripeState = {
    publishableKey: '',
};

const stripe = (state = initialState, action: Action): OmniStore.GlobalStripeState => {
    switch (action.type) {
        case ActionType.SET_STRIPE_PUBLISHABLE_KEY: {
            return {
                ...state,
                publishableKey: action.publishableKey,
            };
        }
        default:
            return state;
    }
};

export default stripe;
