import * as React from 'react';
import styled from 'styled-components/macro';
import { voidPromise, voidVoid } from '../components/NewButton';
import * as vars from '../styles/variables';

interface PriceBoxProps {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: typeof voidPromise | typeof voidVoid;
    selected?: boolean;
}

const PriceBoxDiv = styled.button<PriceBoxProps>`
    display: inline-block;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px 20px;
    height: initial;
    line-height: 21px;
    margin: 10px 10px 10px 0;
    font-size: 15px;
    font-family: quatro;
    background-color: ${(props): string =>
        props.selected ? vars.whiteColor : vars.disabledLightGray};
    border: ${(props): string =>
        props.disabled && !props.selected
            ? 'unset'
            : '1px solid rgba(144, 144, 144, 0.95)'};
    border-color: ${(props): string =>
        props.selected ? vars.lightBlue : vars.borderQuantityGray};
    color: ${(props): string => (props.selected ? vars.blackColor : vars.borderQuantityGray)};
    font-weight: 500;

    &:disabled {
        border: 1px solid rgba(144, 144, 144, 0.95);
        background-color: #f2f2f2;
        color: #000;
        font-weight: 400;
        cursor: initial;
    }

    &:active {
        background-color: rgba(48, 140, 255, 1);
        color: #000;
        background-color: ${(props): string =>
            props.disabled ? 'unset' : 'inherit'};
        background-color: ${(props): string =>
            props.disabled && props.selected
                ? 'rgba(128, 198, 233, .30)'
                : 'inherit'};
        color: ${(props): string => (props.disabled ? '#000' : '#909090')};
    }

    &:hover {
        background-color: rgba(211, 230, 249, 1);
        background-color: ${(props): string | false | undefined =>
            props.selected && 'rgba(128, 198, 233, .30)'};
        background-color: ${(props): string | false | undefined =>
            props.disabled && 'unset'};
        color: #000;
    }

    @media (max-width: 600px) {
        margin: 10px auto;
    }
`;

export const PriceBox: React.FC<PriceBoxProps> = ({
    children,
    ...props
}: PriceBoxProps): JSX.Element => {
    return <PriceBoxDiv {...props}>{children}</PriceBoxDiv>;
};
