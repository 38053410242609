import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import PriceComponent from './PriceComponent';
import Form from './Form';

import * as vars from '../styles/variables';

interface StyleProps {
    gridArea?: string;
    backgroundColor?: string;
    product?: string;
}

const ContentWrapper = styled.div<StyleProps>`
    grid-area: ${props => props.gridArea};
    border: 1px solid black;
    background-color: ${props => props.backgroundColor};
    border-radius: 10px;
    height: max-content;
`;

const Header = styled.div<StyleProps>`
    font-size: 18px;
    background-color: ${props => props.color};
    color: ${props => 
            props.product === 'omnifocus' 
                ? vars.whiteColor
                : vars.blackColor
    };
    padding: 15px;
    text-align: center;
    font-weight: 500;
    border-radius: 7px 7px 0 0;
`;

const UpgradeContainer = styled.div`
    padding: 20px;
`;  

const Platform = styled.div`
    font-size: 14px;
    text-align: center;
`;

const Divider = styled.hr`
    margin: 20px 0;
`;

interface FormProps {
    backgroundColor: string;
    color: string;
    currentVersion: boolean;
    currentVersionUpgrade?: Array<OmniStore.UpgradeInfo>;
    fullPrice?: number;
    gridArea: 'Standard' | 'Pro' | 'Current' | 'Partials';
    inCart: OmniStore.InCartUpgradeInfo;
    lienseType: 'Standard' | 'Pro' | 'Essentials';
    oldVersion?: Array<OmniStore.UpgradeInfo>;
    partials?: Array<OmniStore.UpgradeInfo>;
    platform?: string;
    productVersion: string;
    shortName: string;
    standardOrPro: boolean;
    stdUpgrades?: Array<OmniStore.UpgradeInfo>;
    upgradeItems?: Array<OmniStore.UpgradeInfo>;
    upgradePrice?: number;

    addUpgradesToCart: (type: string) => void;
    handleChange: (e: React.FormEvent<HTMLInputElement>, product: OmniStore.UpgradeInfo, upgradeType: string) => void;
}

const UpgradeForm: React.FC<FormProps> = (props: FormProps) => {
    const intl = useIntl();
    const {
        backgroundColor,
        color,
        currentVersion,
        currentVersionUpgrade,
        gridArea,
        inCart,
        platform,
        productVersion,
        shortName,
        standardOrPro,
        fullPrice,
        upgradeItems,
        upgradePrice,
        addUpgradesToCart,
        handleChange,
    } = props;

    const licenseType = shortName === 'omnioutliner' ? 'Essentials' : 'standard';

    return (
        <ContentWrapper
            backgroundColor={backgroundColor}
            gridArea={gridArea}
        >
            <Header 
                color={color}
                product={shortName}
            >
                <FormattedMessage
                    id="upgradesHeader"
                    values={{
                        licenseType: props.lienseType,
                        version: productVersion
                    }}
                />
            </Header>
            <UpgradeContainer>
                <Platform>{platform}</Platform>
                {!upgradeItems && currentVersion ? (
                        null
                    ) : (
                        <>
                            <PriceComponent
                                currentVersion={false}
                                displayPaymentType={standardOrPro}
                                fullPrice={fullPrice}
                                shortName={shortName}
                                upgradePrice={upgradePrice}
                                upgradeType={gridArea}
                            />
                            <Form
                                color={color}
                                inCart={inCart}
                                shortName={shortName}
                                standardOrPro={standardOrPro}
                                upgradeItems={upgradeItems ?? []}
                                upgradeType={gridArea} 
                                addUpgradesToCart={addUpgradesToCart}
                                handleChange={handleChange}
                            />
                        </>
                    )
                }
                {(standardOrPro && currentVersion) && (
                    <Divider/>
                )}
                {currentVersion && currentVersionUpgrade.length > 0 && (
                    <>
                        <PriceComponent
                            currentVersion={true}
                            displayPaymentType={standardOrPro ? false : true} 
                            fullPrice={currentVersionUpgrade[0].unit_price}
                            shortName={shortName}
                            upgradePrice={currentVersionUpgrade[0].upgrade_price}
                            upgradeType={'Pro'}
                            subHeader={intl.formatMessage(
                                {id: "upgradeCurrentLicenseVersion"},
                                {
                                    licenseType: licenseType,
                                    version: productVersion
                                }
                            )}
                        />
                        <Form
                            color={color}
                            inCart={inCart}
                            shortName={shortName}
                            standardOrPro={false}
                            upgradeItems={currentVersionUpgrade}
                            upgradeType={'Pro'}
                            addUpgradesToCart={addUpgradesToCart}
                            handleChange={handleChange}
                        />
                    </>
                )}
                {/* {(currentVersionAvailable && partialsAvailable) && (
                <Divider/>
                    )} */}
                {/* {partialsAvailable && (
                    <>
                        <PriceComponent
                            currentVersion={false}
                            displayPaymentType={(currentVersion && partialsAvailable) ? false : true }
                            fullPrice={partials[0].unit_price}
                            upgradePrice={partials[0].upgrade_price}
                            subHeader={intl.formatMessage(
                                {id: "upgradePreviousLicenseVersion"},
                                {version: productVersion}
                            )}
                        />
                        <Form
                            color={color}
                            inCart={inCart}
                            standardOrPro={false}
                            upgradeItems={partials}
                            upgradeType="Partials"
                            addUpgradesToCart={addUpgradesToCart}
                            handleChange={handleChange}
                        /> 
                    </>
                )} */}
            </UpgradeContainer>
        </ContentWrapper>
    )
}

export default UpgradeForm;