import { ActionType, Action } from '../actions/types';

const initialState: OmniStore.GlobalSubscriptionState = {
    subscriptions: [],
};

const subscriptions = ( state = initialState, action: Action ): OmniStore.GlobalSubscriptionState => {
    switch (action.type) {
        case ActionType.ADD_SUBSCRIPTIONS: {
            const newSubscriptions = action.subscriptions;
            return {
                ...state,
                subscriptions: newSubscriptions,
            };
        }
        case ActionType.CLEAR_SUBSCRIPTIONS: {
            return {
                ...state,
                subscriptions: [],
            };
        }

        default:
            return state;
    }
};

export default subscriptions;
