import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
    children: React.ReactNode;
}

const portalRoot: HTMLElement | null = document.getElementById('portal-root');

const Portal: React.FC<PortalProps> = ({ children }) => {
    const elRef = useRef<HTMLElement | null>(null);

    if (!elRef.current) {
        elRef.current = document.createElement('div');
    }

    useEffect(() => {
        const el = elRef.current;

        if (el) {
            portalRoot.appendChild(el);
        }

        () => {
            portalRoot.removeChild(el);
        }
    }, []);

    return ReactDOM.createPortal(
        children,
        elRef.current 
    )
}

export default Portal;
