import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import CartIcon from './CartIcon';
import SignInPortal from './SignInPortal'; 
import * as vars from '../styles/variables';
// import SignInFormBits from './SignInForm';

const Header = styled.div`
    display: grid;
    grid-template-columns: 100px auto 100px;
    margin: 30px auto;
    align-items: end;

    @media (max-width: 500px) {
        display: grid;
        grid-template-columns: 50px auto 50px;
    }
`;

const RightSide = styled.div`
    grid-column-start: 3;
    margin: 0 20px;
    align-self: end;

    @media (max-width: 420px) {
        justify-self: center;
    }
`;

const MiddleHeader = styled.h1`
    grid-column-start: 2;
    margin: 0 20px;
    text-align: center;
    color: ${vars.whiteColor};
    font-size: 34px;
    line-height: 1.2em;

    a {
        color: ${vars.whiteColor};
        text-decoration: none;
    }

    @media (max-width: 420px) {
        grid-column-start: 2;
        grid-column-end: 3;
        font-size: 30px;
    }
`;

interface StoreHeaderProps {
    inventoryColor: string;
}

const StoreHeader: React.FC<StoreHeaderProps> = (props: StoreHeaderProps) => {
    const location = useLocation();
    return (
        <Header>
            <MiddleHeader>
                <Link to='/'>
                    <FormattedMessage id='omnistoretitle' />
                </Link>
            </MiddleHeader>

            <RightSide>
                <CartIcon color={props.inventoryColor}/>
            </RightSide>

            {location.pathname !== '/signin' && (
                <SignInPortal forceSignInFormOpen={false} />
            )}
        </Header>
    );
};

export default StoreHeader;
