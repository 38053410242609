import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import * as vars from '../styles/variables';

const PriceContainer = styled.div`
    padding: 15px 0 0;
    text-align: center;

    #full-price {
        color: ${vars.darkGray};
        font-weight: 500;
        margin: 5px;

        > span {
            font-size: 14px;
            padding: 10px;
        }
    }

    > p {
        font-size: 14px;
    }
`;

const Price = styled.div`
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    position: relative;

    > span {
        font-size: 18px;
        padding: 10px;
    }

`;

interface PriceComponentProps {
    currentVersion: boolean;
    displayPaymentType: boolean;
    fullPrice: number;
    shortName: string;
    upgradePrice: number;
    upgradeType: 'Standard' | "Pro" | "Current" | 'Partials';
    subHeader?: string;
}

const PriceComponent: React.FC<PriceComponentProps> = (props: PriceComponentProps) => {
    const { 
        currentVersion,
        displayPaymentType,
        fullPrice,
        shortName,
        upgradePrice,
        upgradeType, 
    } = props;

    const omniOutlinerStandard = shortName === 'omnioutliner' && upgradeType === 'Standard';

    return (
        <PriceContainer>
            <Price>
                <FormattedNumber
                    currency='USD'
                    style={`currency`}
                    value={upgradePrice/100}
                />
                <span>USD</span>
            </Price>
            {omniOutlinerStandard && (
                <p id="full-price">
                    <FormattedMessage id="noDiscountAvailable"/>
                </p>
            )}
            {(!currentVersion && !omniOutlinerStandard) && (
                <p id="full-price">Full price: &nbsp;
                    <FormattedNumber
                        currency='USD'
                        style={`currency`}
                        value={fullPrice/100}
                    />
                    <span>USD</span>
                </p>
            )}
            {displayPaymentType && (
                <p>
                    <FormattedMessage id="licensePaymentType"/>
                </p>
            )}
            <p>{props.subHeader}</p>
        </PriceContainer>
    )
}

export default PriceComponent;