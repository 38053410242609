import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'react-content-loader';
import { captureException, setUser } from '@sentry/react';
import { SetupIntent, StripeCardElementChangeEvent } from '@stripe/stripe-js';

import BillingDetails from './BillingDetails';
import NewButton from './NewButton';
import { Error } from './Error';
import Receipt from './Receipt';
import Success from './Success';

import { postPaymentIntent } from '../utils/postPaymentIntent';
import postSubscribe from '../utils/postSubscribe';
import { postSetupIntent } from '../utils/postSetupIntent';
import postCreateCustomer from '../utils/postCreateCustomer';
import postRegisterLicense from '../utils/postRegister';
import postCreateRefund from '../utils/postCreateRefund';
import { getUserInfo } from '../utils/getUserData';

import * as vars from '../styles/variables';

const FormContainer = styled.div`
    background-color: ${vars.whiteColor};
    padding: 60px;
    border-radius: 7px;
`;

const CardForm = styled.form`
    max-width: 540px;
    margin: auto;
    
    label {
        font-weight: 500;
    }

    > h2 {
        color: ${vars.whiteColor};
        text-align: center;
        font-size: 30px;
    }
`;

const NoRefundText = styled.p`
    font-size: 12px;
`;


const EmailForReceipt = styled.div`
    margin: 20px auto;
    text-align: center;
    font-weight: 500;

    > span {
        display: block;
        font-size: 18px;
        font-weight: 700;
    }
`;

const StripeText = styled.div`
    margin: 20px auto;
    max-width: 400px;
    p {
        font-size: 12px;
    }
`;

const Payments = styled.div`
    display: flex;
    flex-direction: column;
    /* max-width: 500px; */
    margin: 20px auto;
`;


const CardContainer = styled.div`
    border-radius: 3px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #909090;
    line-height: 1.5em;
    font-size: 16px;
    margin-bottom: 20px;
`;

interface BillingFormProps {
    showApplePay?: boolean;
}

interface SucceededStructure {
    licenseOnly?: boolean;
    listOfLicenses?: Array<OmniStore.LicenseCartItem>;
    listOfLicenseUpgrades?: Array<OmniStore.LicenseUpgradeCartItem>;
    listOfSubscriptions?: Array<OmniStore.SubscriptionCartItem>;
    status: boolean;
}
interface TaxRateInformation {
    jurisdiction?: string;
    tax_rate: string;
}

const BillingInformation: React.FC<BillingFormProps> = () => {
    const stripe = useStripe();
    const elements = useElements();

    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);

    const [setupIntentSecret, setSIClientSecret] = useState<string | null>(null);
    const [paymentIntentSecret, setPIClientSecret] = useState<string | null>(null);
    const [paymentIntentError, setPaymentIntentErrorMessage] = useState<string | null>(null);
    const [oneCustomerCreateCall, setCustomerCreation] = useState<boolean>(false);
    const [setupIntentCreation, stopSetupIntentCreation] = useState<boolean>(false);
    const [taxDetermined, setTaxDetermined] = useState<boolean>(false);

    const [cardComplete, setCardComplete] = useState<boolean>(false);
    const [succeeded, setSuccess] = useState<SucceededStructure>({
        licenseOnly: false,
        listOfLicenseUpgrades: undefined,
        listOfLicenses: undefined,
        listOfSubscriptions: undefined,
        status: false,
    });
    const [taxRateIfApplicable, setTaxRate] = useState<TaxRateInformation>({
            jurisdiction: undefined,
            tax_rate: '0',
        });

    const [customerNameOnCard, setNameOnCard] = useState<string>('');

    const [customerAddressLineOne, setAddressLineOne] = useState<string>('');
    const [customerAddressLineTwo, setAddressLineTwo] = useState<string>('');
    const [customerCity, setCity] = useState<string>('');
    const [customerCountry, setCountry] = useState<string>('');
    const [customerRegion, setRegion] = useState<string>('');
    const [customerPostalCode, setCustomerPostalCode] = useState<string>('');
    const [companyCustomerAddressLineOne, setCompanyAddressLineOne] = useState<string>('');
    const [companyCustomerAddressLineTwo, setCompanyAddressLineTwo] = useState<string>('');
    const [companyCustomerCity, setCompanyCity] = useState<string>('');
    const [companyCustomerCountry, setCompanyCountry] = useState<string>('');
    const [companyCustomerRegion, setCompanyRegion] = useState<string>('');
    const [companyCustomerPostalCode, setCompanyCustomerPostalCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [purchaseAPIError, setPurchaseAPIError] = useState<string>('');
    const [addressError, setAddressError] = useState<boolean>(false);

    const [newStripeCustomerID, setJustCreatedCustomerId] = useState<string | undefined>(undefined);
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const cartFromAPI = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.serverCart,}));
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const signedInAccount = useSelector((state: OmniStore.GlobalReduxState) => ({ account: state.accounts.expandedUserInfo,}));
    const [subscriptionCartEstimate, setSubscriptionCartEstimate] = useState(0);

    useEffect(() => {
        if (addressError === true) {

        }
    }, [addressError])

    useEffect(() => {
        if (!signedInAccount) return;

        if (signedInAccount) {
            setUser({
                username:
                    signedInAccount.account !== undefined
                        ? signedInAccount.account.username
                        : undefined,
            });
        }
    }, [signedInAccount]);

    useEffect(() => {
        let newSubscriptionTotalCostEstimate = 0;
        cartItems.cart.subscriptions.map((item: OmniStore.SubscriptionCartItem) => {
            if (item.payInterval === 'MONTHLY') {
                newSubscriptionTotalCostEstimate +=
                    (item.monthlyPrice * item.quantity) / 100;
                return true;
            } else if (item.payInterval === 'YEARLY') {
                newSubscriptionTotalCostEstimate +=
                    (item.yearlyPrice * item.quantity) / 100;
                return true;
            }
            return false;
        });
        setSubscriptionCartEstimate(newSubscriptionTotalCostEstimate);
    }, [cartItems]);

    const [newCustomerStripeID, setNewCustomerStripeID] = React.useState<string | undefined>();

    const [serverError, setServerError] = React.useState<string>('');

    const [customerVATID, setCustomerVATID] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [additionalNotes, setAdditionalNotes] = useState<string>('');
    const [cardError, setCardError] = useState<boolean>(false);
    const [cardErrorMessage, setCardErrorMessage] = useState<string>('');
    const [alternateEmailForReceipt, setEmail] = useState<string>('');
    const [nonundefinedemail, setSubscriptionEmail] = useState<string>('');

    useEffect(() => {
        if (signedInAccount.account) {
            setEmail(signedInAccount.account?.email);
            setSubscriptionEmail(signedInAccount.account?.email);
        }
    }, [signedInAccount.account]);

    const doSubscriptionWorkWithoutSetupIntent = async (payment_method: string): Promise<void> => {
        setLoading(true);

        if (signedInAccount === undefined) {
            return;
        }
        const completedSubscribed: Array<OmniStore.SubscriptionCartItem | OmniStore.LicenseCartItem> = [];
        const incompleteSubscribed: Array<string> = [];
        const customerID =
            signedInAccount 
                ? signedInAccount.account?.customer_id[0]
                : newCustomerStripeID;

        if (signedInAccount.account && signedInAccount && customerID) {
            const arrayOfPromises = cartItems.cart.subscriptions.map((plan) => {
                postSubscribe({
                    accessToken: accessToken,
                    customer_id: customerID,
                    email: nonundefinedemail,
                    jurisdiction: taxRateIfApplicable.jurisdiction ?? undefined,
                    metadata: {
                        additionalNotes: additionalNotes,
                        companyCustomerCountry: companyCustomerCountry,
                        companyCustomerPostalCode: companyCustomerPostalCode,
                        companyCustomerRegion: companyCustomerRegion,
                        companyName: companyName,
                        companyaddressOne: companyCustomerAddressLineOne,
                        companyaddressTwo: companyCustomerAddressLineTwo,
                        companycity: companyCustomerCity,
                        customerVATID: customerVATID,
                    },
                    payment_method_id: payment_method,
                    plans: [
                        {
                            plan_id:
                                plan.payInterval === 'MONTHLY'
                                    ? plan.monthlyStripeID
                                    : plan.yearlyStripeID,
                            quantity: plan.quantity,
                        },
                    ],
                    purchaseApiUrl,
                    tax_rate: taxRateIfApplicable.tax_rate
                        ? taxRateIfApplicable.tax_rate
                        : '0',
                })
                    .then(() => completedSubscribed.push(plan))
                    .catch((error) => incompleteSubscribed.push(error));
                return plan;
            });
            Promise.all(arrayOfPromises).then(() => {
                setLoading(false);
                localStorage.removeItem('cart');
            });
        } else {
            console.log('Proper customer ID or account not found');
        }
        return;
    };

    const doSubscriptionWork = async (setupIntent: SetupIntent): Promise<void> => {
        setLoading(true);

        if (!signedInAccount || !setupIntent) return;

        const completedSubscribed: Array<OmniStore.SubscriptionCartItem | OmniStore.LicenseCartItem> = [];
        const incompleteSubscribed: Array<string> = [];
        const customerID =
            signedInAccount.account.customer_id
                ? signedInAccount.account?.customer_id[0]
                : newCustomerStripeID;

        if (signedInAccount.account && signedInAccount && customerID) {
            const arrayOfPromises = cartItems.cart.subscriptions.map((plan) => {
                postSubscribe({
                    accessToken: accessToken,
                    customer_id: customerID,
                    email: nonundefinedemail,
                    jurisdiction: taxRateIfApplicable.jurisdiction ?? undefined,
                    metadata: {
                        additionalNotes: additionalNotes,
                        companyCustomerCountry: companyCustomerCountry,
                        companyCustomerPostalCode: companyCustomerPostalCode,
                        companyCustomerRegion: companyCustomerRegion,
                        companyName: companyName,
                        companyaddressOne: companyCustomerAddressLineOne,
                        companyaddressTwo: companyCustomerAddressLineTwo,
                        companycity: companyCustomerCity,
                        customerVATID: customerVATID,
                    },
                    payment_method_id: setupIntent.payment_method
                        ? setupIntent.payment_method
                        : undefined,
                    plans: [
                        {
                            plan_id:
                                plan.payInterval === 'MONTHLY'
                                    ? plan.monthlyStripeID
                                    : plan.yearlyStripeID,
                            quantity: plan.quantity,
                        },
                    ],
                    purchaseApiUrl: purchaseApiUrl,
                    tax_rate: taxRateIfApplicable.tax_rate
                        ? taxRateIfApplicable.tax_rate
                        : '0',
                })
                    .then(() => completedSubscribed.push(plan))
                    .catch((error) => incompleteSubscribed.push(error));
                return plan;
            });
            Promise.all(arrayOfPromises).then(() => {
                setLoading(false);
                localStorage.removeItem('cart');
                setSuccess({
                    licenseOnly: false,
                    listOfLicenseUpgrades: undefined,
                    listOfLicenses: undefined,
                    listOfSubscriptions: cartItems.cart.subscriptions,
                    status: true,
                });
            });
        } else {
            console.log('Proper customer ID or account not found');
        }
        return;
    };

    

    // function to post a setupIntent and store it locally
    const createAndSaveInitialSetupIntent = async (
        customer_id: string
    ): Promise<void> => {
        console.log(customer_id);
        await postSetupIntent({
            accessToken: accessToken,
            customer_id: customer_id,
            purchaseApiUrl: purchaseApiUrl,
            usage: 'off_session',
        }).then((result) => {
            console.log(result);
            setSIClientSecret(result.success?.setupIntentClientSecret);
        });
    };

    const createAndSaveInitialPaymentIntent = async (
        customer_id: string
    ): Promise<void> => {
        const licensesJson = cartItems.cart.licenses.map((item) => {
            if (item.standardOrPro === 'PRO') {
                return { product_id: item.productID, quantity: item.quantity };
            }
            return {
                product_id: item.productID.replace('_PRO', '_STD'), //delete _PRO if not PRO
                quantity: item.quantity,
            };
        });

        const upgradeJson = {};
        cartItems.cart.upgrades.forEach((item) => {
            const upgrade_to = item.upgradeTo;
            const upgradeItem = {
                registration_identifier: item.registration_identifier,
                upgrade_quantity: item.quantity
            }
            if (!upgradeJson[upgrade_to]) {
                upgradeJson[upgrade_to] = [];
            }
            upgradeJson[upgrade_to].push(upgradeItem);
        })

        const paymentIntentCall = await postPaymentIntent({
            accessToken,
            cart: {
                new_purchases: licensesJson,
                upgrades: upgradeJson,
            },
            customer_id,
            initialAmount: cartFromAPI.cart.pricing.final_price,
            purchaseApiUrl: purchaseApiUrl,
        });

        if (paymentIntentCall.errors) {
            setPaymentIntentErrorMessage(paymentIntentCall.errors[0].message);
        }
        if (paymentIntentCall.cs) {
            setPIClientSecret(paymentIntentCall.cs);
        }
    };

    useEffect(() => {
        // what we do when the page first loads?
        if (!signedInAccount && !newStripeCustomerID) return;

        let activatedCharcoal = false;

        // function to postCreateCustomer and save it to local state.
        const createAndRefreshCustomerID = async (): Promise<void> => {
            if (oneCustomerCreateCall === true) {
                return;
            }
            if (signedInAccount.account === undefined) return;
            if (
                signedInAccount.account.email &&
                signedInAccount.account.omni_id &&
                signedInAccount.account.username &&
                oneCustomerCreateCall === false
            ) {
                setCustomerCreation(true);
                postCreateCustomer({
                    accessToken: accessToken,
                    email: signedInAccount.account.email,
                    omni_id: signedInAccount.account.omni_id,
                    omni_username: signedInAccount.account.username,
                    purchaseApiUrl: purchaseApiUrl,
                })
                    .then((response) => {
                        setJustCreatedCustomerId(response.customer_id);
                        setNewCustomerStripeID(response.customer_id);
                        getUserInfo(accessToken, accountsApiUrl);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };

        if (signedInAccount && paymentIntentSecret === null) {
            if (signedInAccount.account?.customer_id) {               
                if (
                    cartItems.cart.subscriptions.length > 0 &&
                    cartItems.cart.licenses.length === 0 &&
                    cartItems.cart.upgrades.length === 0 && 
                    setupIntentSecret === null
                    ) {
                        createAndSaveInitialSetupIntent(
                        signedInAccount.account.customer_id[0]
                    );
                }
                if (
                    cartItems.cart.licenses.length > 0 &&
                    activatedCharcoal === false
                ) {
                    activatedCharcoal = true;
                    createAndSaveInitialPaymentIntent(
                        signedInAccount.account.customer_id[0]
                    );
                }
                if (
                    cartItems.cart.upgrades.length > 0 &&
                    activatedCharcoal === false
                ) {
                    activatedCharcoal = true;
                    createAndSaveInitialPaymentIntent(
                        signedInAccount.account.customer_id[0]
                    );
                }
            }
        } else if (newStripeCustomerID) {
            if (
                cartItems.cart.subscriptions.length > 0 &&
                cartItems.cart.licenses.length === 0 &&
                cartItems.cart.upgrades.length === 0 
            ) {
                createAndSaveInitialSetupIntent(newStripeCustomerID);
            }
            if (cartItems.cart.licenses.length > 0) {
                createAndSaveInitialPaymentIntent(newStripeCustomerID);
            }
            if (cartItems.cart.upgrades.length > 0) {
                createAndSaveInitialPaymentIntent(newStripeCustomerID);
            }
        }
        if (
            signedInAccount.account !== undefined &&
            signedInAccount.account.email !== undefined &&
            signedInAccount.account.omni_id !== undefined &&
            signedInAccount.account.username !== undefined &&
            signedInAccount.account.customer_id === undefined
        ) {
            createAndRefreshCustomerID();
        }
    }, [signedInAccount.account, oneCustomerCreateCall, newStripeCustomerID]);

    useEffect(() => {
        //this effect is only for the "customer doesn't have customer_id yet" case
        if (setupIntentCreation) {
            return;
        }
        if (
            oneCustomerCreateCall === true &&
            newStripeCustomerID !== undefined &&
            succeeded.status === false
        ) {
            if (cartItems.cart.subscriptions.length > 0) {
                createAndSaveInitialSetupIntent(newStripeCustomerID);
            }
            if (cartItems.cart.licenses.length > 0) {
                createAndSaveInitialPaymentIntent(newStripeCustomerID);
            }
            if (cartItems.cart.upgrades.length > 0) {
                createAndSaveInitialPaymentIntent(newStripeCustomerID);
            }
            stopSetupIntentCreation(true);
        }
        //eslint-disable-next-line
    }, [newStripeCustomerID, oneCustomerCreateCall, setupIntentCreation]);

    const handleCardChange = (event: StripeCardElementChangeEvent) => {
        if (event.complete) {
            setCardComplete(true);
        } else {
            setCardComplete(false);
        }
    };

    const handleSubmit = async (event: React.FormEvent): Promise<void> => {
        setFormLoading(true);
        setPurchaseAPIError('');
        event.preventDefault();
        let breakOutBool = false;

        if (!stripe || !elements || !cardComplete) {
            setFormLoading(false);
            setPurchaseAPIError('');
            return;
        }

        let customerID: string;

        if (signedInAccount !== undefined) {
            if (signedInAccount.account.customer_id !== undefined) {
                customerID = signedInAccount.account?.customer_id[0];
            } else {
                customerID = newCustomerStripeID;
            }
        } else if (newCustomerStripeID !== undefined) {
            customerID = newCustomerStripeID;
        }

        let paymentmethodidfrompaymentintent;
        const cardElement = elements.getElement(CardElement);
        if (
            (cartItems.cart.licenses.length > 0 || cartItems.cart.upgrades.length > 0) &&
            paymentIntentSecret !== null
        ) {
            if (cardElement !== null) {
                const { error, paymentIntent } =
                    await stripe.confirmCardPayment(paymentIntentSecret, {
                        payment_method: {
                            billing_details: {
                                address: {
                                    city: customerCity,
                                    country: customerCountry,
                                    line1: customerAddressLineOne,
                                    line2: customerAddressLineTwo,
                                    postal_code: customerPostalCode,
                                    state: customerRegion,
                                },
                                name: customerNameOnCard,
                            },
                            card: cardElement,
                        },
                    });
                // save the payment method id for use on a subscribe call, if applicable.
                paymentmethodidfrompaymentintent =
                    paymentIntent?.payment_method;

                if (error) {
                    if (
                        error.type === 'card_error' ||
                        error.type === 'invalid_request_error' ||
                        error.code === 'card_declined'
                    ) {
                        breakOutBool = true;
                        setCardError(true);
                        setCardErrorMessage(
                            'An error occurred processing the card information above. Review the card details, then try again.'
                        );
                        captureException(error);
                        setFormLoading(false);
                        return;
                    }
                    setCardError(true);
                    setCardErrorMessage(
                        'An error occurred processing the card information above. Review the card details, then try again.'
                    );
                    setFormLoading(false);
                    console.log('[error]', error);
                    return;
                }
                if (paymentIntent !== undefined && accessToken !== undefined) {
                    await postRegisterLicense({
                        accessToken: accessToken,
                        accountsApiURL: accountsApiUrl,
                        payment_intent_id: paymentIntent.id,
                    })
                        .then((result) => {
                            console.log(result);
                            if (result.status === 'error') {
                                breakOutBool = true;
                                setPurchaseAPIError(
                                    'We had a problem registering the purchase. The payment was processed, so we’ve automatically issued a refund. We’d love to figure out what happened — please email sales@omnigroup.com'
                                );
                                return;
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            breakOutBool = true;
                            setPurchaseAPIError(
                                'We had a problem registering the purchase. The payment was processed, so we’ve automatically issued a refund. We’d love to figure out what happened — please email sales@omnigroup.com'
                            );
                            return;
                        });
                    if (breakOutBool && customerID !== undefined) {
                        await postCreateRefund({
                            accessToken: accessToken,
                            customer_id: customerID,
                            payment_intent_id: paymentIntent.id,
                            purchaseApiUrl: purchaseApiUrl,
                        });
                    }
                } else {
                    breakOutBool = true;
                    console.log('Something went wrong.');
                    return;
                }

                if (
                    cartItems.cart.subscriptions.length === 0 &&
                    !breakOutBool
                ) {
                    // set success.status to true if there aren’t subscriptions to work on.
                    setSuccess({
                        licenseOnly: true,
                        listOfLicenseUpgrades: cartItems.cart.upgrades,
                        listOfLicenses: cartItems.cart.licenses,
                        listOfSubscriptions: undefined,
                        status: true,
                    });
                }
            } else {
                return;
            }
        }

        if (
            setupIntentSecret !== null &&
            cartItems.cart.upgrades.length === 0 &&
            cartItems.cart.licenses.length === 0 &&
            cartItems.cart.subscriptions.length > 0 &&
            cardElement !== null
        ) {
            const { error, setupIntent } = await stripe.confirmCardSetup(
                setupIntentSecret,
                {
                    payment_method: {
                        billing_details: {
                            address: {
                                city: customerCity,
                                country: customerCountry,
                                line1: customerAddressLineOne,
                                line2: customerAddressLineTwo,
                                postal_code: customerPostalCode,
                                state: customerRegion,
                            },
                            name: customerNameOnCard,
                        },
                        card: cardElement,
                    },
                }
            );
            if (error) {
                setCardError(true);
                setCardErrorMessage(
                    'An error occurred processing the card information above. Review the card details, then try again.'
                );
                console.log('[error]', error);
                captureException(error);
                if (
                    error.type === 'invalid_request_error' &&
                    signedInAccount.account !== undefined
                ) {
                    sessionStorage.removeItem('setupIntentClientSecret');
                    sessionStorage.removeItem('setupIntentID');

                    const setupIntentResult = await postSetupIntent({
                        accessToken: accessToken,
                        customer_id: signedInAccount.account?.customer_id[0],
                        purchaseApiUrl: purchaseApiUrl,
                        usage: 'off_session',
                    });
                    if (setupIntentResult.success !== undefined) {
                        console.log(setupIntentResult);
                        setSIClientSecret(
                            setupIntentResult.success.setupIntentClientSecret
                        );
                    } else {
                        setServerError('Something is going wrong.');
                    }
                }
                if (error.type === 'card_error') {
                    setCardError(true);
                    setCardErrorMessage(
                        'An error occurred processing the card information above. (It might be an incorrect number, or insufficient funds.)'
                    );
                    setFormLoading(false);
                }
                return;
            }
            if (setupIntent !== undefined) {
                doSubscriptionWork(setupIntent);
            } else {
                console.log('Something went wrong.');
            }
        }
        if (
            (cartItems.cart.upgrades.length > 0 || cartItems.cart.licenses.length > 0) &&
            cartItems.cart.subscriptions.length > 0
        ) {
            if (paymentmethodidfrompaymentintent) {
                doSubscriptionWorkWithoutSetupIntent(
                    paymentmethodidfrompaymentintent
                );
            } else {
                console.log(
                    'Unable to subscribe. Try again, in a separate order.'
                );
            }
        }

        setFormLoading(false);
        // here is where i should be setting the success, and only here.
        if (breakOutBool) {
            return;
        }
        setSuccess({
            licenseOnly: false,
            listOfLicenseUpgrades: cartItems.cart.upgrades,
            listOfLicenses: cartItems.cart.licenses,
            listOfSubscriptions: cartItems.cart.subscriptions,
            status: true,
        });
    };

    if (succeeded.status === true) {
        return (
            <Success
                licenses={succeeded.listOfLicenses}
                subscriptions={succeeded.listOfSubscriptions}
                upgrades={succeeded.listOfLicenseUpgrades}
            />
        );
    }

    if (stripe === null) {
        return null;
    }

    if (paymentIntentError) {
        return <div style={{color: 'white'}}>{paymentIntentError}</div>
    }

    if (setupIntentSecret === null && paymentIntentSecret === null) {
        return <List />;
    }

    return (
        <Payments>
            <BillingDetails
                addressError={addressError}
                additionalNotes={additionalNotes}
                cardError={cardError}
                cardErrorMessage={cardErrorMessage}
                companyCustomerAddressLineOne={companyCustomerAddressLineOne}
                companyCustomerAddressLineTwo={companyCustomerAddressLineTwo}
                companyCustomerCity={companyCustomerCity}
                companyCustomerCountry={companyCustomerCountry}
                companyCustomerPostalCode={companyCustomerPostalCode}
                companyCustomerRegion={companyCustomerRegion}
                companyName={companyName}
                customerAddressLineOne={customerAddressLineOne}
                customerAddressLineTwo={customerAddressLineTwo}
                customerCity={customerCity}
                customerCountry={customerCountry}
                customerNameOnCard={customerNameOnCard}
                customerPostalCode={customerPostalCode}
                customerRegion={customerRegion}
                customerVATID={customerVATID}
                newCustomerStripeID={newCustomerStripeID}
                serverError={serverError}
                setAddressLineOne={setAddressLineOne}
                setAddressLineTwo={setAddressLineTwo}
                setAdditionalNotes={setAdditionalNotes}
                setAddressError={setAddressError}
                setCity={setCity}
                setCountry={setCountry}
                setRegion={setRegion}
                setCompanyAddressLineOne={setCompanyAddressLineOne}
                setCompanyAddressLineTwo={setCompanyAddressLineTwo}
                setCompanyCity={setCompanyCity}
                setCompanyCountry={setCompanyCountry}
                setCompanyCustomerPostalCode={setCompanyCustomerPostalCode}
                setCompanyRegion={setCompanyRegion}
                setCustomerPostalCode={setCustomerPostalCode}
                setCompanyName={setCompanyName}
                setCustomerVATID={setCustomerVATID}
                setNameOnCard={setNameOnCard}
                setServerError={setServerError}
                setTaxDetermined={setTaxDetermined}
                setTaxRate={setTaxRate}
                taxDetermined={taxDetermined}
            />
            
            {loading && <List />}

            {!paymentIntentError && !addressError && taxDetermined && !loading && (
                <CardForm onSubmit={handleSubmit}>
                    <h2>Your Order</h2>
                    <FormContainer>
                        <Receipt
                            cartFromApi={cartFromAPI.cart}
                            licenseItems={cartItems.cart.licenses}
                            subscriptionEstimate={subscriptionCartEstimate}
                            licenseUpgradeItems={cartItems.cart.upgrades}
                            subscriptionItems={cartItems.cart.subscriptions}
                            taxRate={taxRateIfApplicable.tax_rate}
                        />
                        <EmailForReceipt>
                            <p>
                                <FormattedMessage id='receiptFor' />
                            </p>
                            <span>
                                {alternateEmailForReceipt !== '' ? (
                                    alternateEmailForReceipt
                                ) : (
                                    <em>pending</em>
                                )}
                            </span>
                        </EmailForReceipt>
                        {cartItems.cart.subscriptions.length >= 1 && (
                            <NoRefundText>
                                <FormattedMessage id='autoRenewText' />
                            </NoRefundText>
                        )}
                        {cartItems.cart.subscriptions.length >= 1 && (
                            <NoRefundText>
                                <FormattedMessage id='noRefundText' />
                            </NoRefundText>
                        )}
                        <label style={{ display: 'block', marginBottom: '10px' }}>
                            Card:
                        </label>
                        {(setupIntentSecret !== null ||
                            paymentIntentSecret !== null) && (
                            <CardContainer>
                                <CardElement onChange={handleCardChange} />
                            </CardContainer>
                        )}
                        {cardError && <Error>{cardErrorMessage}</Error>}
                        <StripeText>
                            <p>
                                <FormattedMessage id='iAuthorizeOmniGroupToUse' />
                            </p>
                        </StripeText>
                        {purchaseAPIError.length > 0 ? (
                            <Error>{purchaseAPIError}</Error>
                        ) : (
                            <NewButton
                                disabled={cardComplete !== true || formLoading}
                                center
                                id='CompletePurchaseButton'
                                minWidth='300px'
                                showLoading={formLoading}
                            >
                                Complete Purchase
                            </NewButton>
                        )}
                    </FormContainer>
                </CardForm>
            )}
        </Payments>
    );
};

export default BillingInformation;
