import * as React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const TotalCost = styled.div`
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    max-width: 540px;
`;

const Grouping = styled.div`
    margin: 5px 0 10px 0;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
        margin: 50px 0 5px 0;
        font-style: italic;

        &:first-of-type {
            /* margin-top: 30px; */
            margin-top: 0;
        }
    }
`;

const ProductPrice = styled.div`
    width: 30%;
    font-size: 14px;
    text-align: right;
`;

const ProductName = styled.div`
    font-size: 16px;
    font-weight: 600;
    width: 70%;
`;

const ProductRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    width: 100%;
`;

const TotalAppWithTaxCost = styled.div`
    display: flex;
    flex-direction: row;
    border-top: 1px solid #000;
    margin: 10px 0 0;
    padding: 10px 0;
    width: 100%;
`;

const Figures = styled.span`
    flex-grow: 1;
    min-width: 100px;
`;

const Subscript = styled.div`
    color: #808080;
    width: 100%;
    font-size: 12px;
`;

const QuantityRow = styled.span`
    width: 100%;
    font-size: 14px;
`;

const ReceiptItem = styled.div`
    width: 100%;
    margin: 5px 0 15px;
`;

interface ReceiptProps {
    cartFromApi: OmniStore.CartFromAPI;
    licenseItems: Array<OmniStore.LicenseCartItem>;
    licenseUpgradeItems: Array<OmniStore.LicenseUpgradeCartItem>;
    subscriptionEstimate: number;
    subscriptionItems: Array<OmniStore.SubscriptionCartItem>;
    taxRate: string;
}

const Receipt: React.FC<ReceiptProps> = (props) => {
    const productVersion = useSelector((state: OmniStore.GlobalReduxState) => state.currentMajorVersions);

    return (
        <TotalCost>
            <Grouping>
                {props.subscriptionItems.length > 0 && <h3>Subscriptions</h3>}
                {props.subscriptionItems.map((item, fakeKey) => {
                    return (
                        <ReceiptItem key={fakeKey}>
                            <ProductRow>
                                <ProductName>
                                    {item.displayName}{' '}
                                    {item.payInterval === 'MONTHLY'
                                        ? '- Monthly'
                                        : '- Yearly'}
                                </ProductName>
                                <ProductPrice>
                                    $
                                    {item.payInterval === 'MONTHLY'
                                        ? (
                                            item.monthlyPrice *
                                            item.quantity *
                                            0.01
                                        ).toFixed(2)
                                        : (
                                            item.yearlyPrice *
                                            item.quantity *
                                            0.01
                                        ).toFixed(2)}
                                </ProductPrice>
                            </ProductRow>
                            <QuantityRow>
                                {' '}
                                <FormattedMessage id='Quantity' />:{' '}
                                {item.quantity}
                            </QuantityRow>
                        </ReceiptItem>
                    );
                })}
                {props.subscriptionItems.length > 0 && (
                    <React.Fragment>
                        <ProductRow>
                            <ProductName>Subscription tax:</ProductName>
                            <ProductPrice>
                                <Figures>
                                    <FormattedNumber
                                        currency='USD'
                                        style={`currency`}
                                        value={
                                            props.subscriptionEstimate *
                                            parseFloat(props.taxRate)
                                        }
                                    />
                                </Figures>
                            </ProductPrice>
                        </ProductRow>
                        <ProductRow>
                            <ProductName>Subscription subtotal:</ProductName>
                            <ProductPrice>
                                <Figures>
                                    <FormattedNumber
                                        currency='USD'
                                        style={`currency`}
                                        value={
                                            props.subscriptionEstimate +
                                            props.subscriptionEstimate *
                                                parseFloat(props.taxRate)
                                        }
                                    />
                                </Figures>
                            </ProductPrice>
                        </ProductRow>
                        <Subscript>
                            Taxes, if applicable, are collected on separate
                            invoices for mixed-timeline subscriptions.
                        </Subscript>
                    </React.Fragment>
                )}
                {props.licenseItems.length > 0 && <h3>Licenses</h3>}
                {props.licenseItems.map((item, fakeKey) => {
                    return (
                        <ReceiptItem key={fakeKey}>
                            <ProductRow>
                                <ProductName>
                                    {item.standardOrPro === 'PRO'
                                        ? item.displayName.includes('Plan')
                                            ? item.displayName.replace(
                                                'OmniPlan 4',
                                                'OmniPlan 4 Pro'
                                            )
                                            : item.displayName.replace(
                                                'for Mac',
                                                'Pro for Mac'
                                            )
                                        : item.displayName.includes(
                                            'OmniOutliner 5'
                                        )
                                        ? 'OmniOutliner 5 Essentials for Mac'
                                        : item.displayName}
                                </ProductName>
                                <ProductPrice>
                                    <FormattedNumber
                                        style={`currency`}
                                        currency='USD'
                                        value={
                                            item.standardOrPro === 'PRO'
                                                ? item.price *
                                                item.quantity *
                                                0.01
                                                : item.price *
                                                item.quantity *
                                                0.01
                                        }
                                    />
                                </ProductPrice>
                            </ProductRow>
                            <QuantityRow>
                                {' '}
                                <FormattedMessage id='Quantity' />:{' '}
                                {item.quantity}
                            </QuantityRow>
                        </ReceiptItem>
                    );
                })}
                {props.licenseUpgradeItems.map((item, fakeKey) => {
                    const OOStandardUpgrade = item.shortName === 'omnioutliner' && item.standardOrProUpgrade === 'STANDARD';
                    const upgrade_to = productVersion[item.shortName];
                    return (
                        <ReceiptItem key={fakeKey}>
                            <ProductRow>
                                <ProductName>
                                    {upgrade_to} {OOStandardUpgrade ? 'Essentials' : item.standardOrProUpgrade} License Upgrade
                                </ProductName>
                                <ProductPrice>
                                    <FormattedNumber
                                        style={`currency`}
                                        currency='USD'
                                        value={item.upgradePrice *
                                                item.quantity *
                                                0.01
                                        }
                                    />
                                </ProductPrice>
                            </ProductRow>
                            <QuantityRow>
                                {' '}
                                <FormattedMessage id='Quantity' />:{' '}
                                {item.quantity}
                            </QuantityRow>
                        </ReceiptItem>
                    );
                })}
            </Grouping>
            <Grouping>
                {(props.licenseItems.length > 0 || props.licenseUpgradeItems.length > 0) && (
                    <React.Fragment>
                        {props.cartFromApi.pricing.bundle_discount_amount >
                            0 && (
                            <ProductRow>
                                <ProductName>Quantity discount:</ProductName>
                                <ProductPrice>
                                    <Figures>
                                        <FormattedNumber
                                            style={`currency`}
                                            currency='USD'
                                            value={
                                                -props.cartFromApi.pricing
                                                    .bundle_discount_amount /
                                                100
                                            }
                                        />
                                    </Figures>
                                </ProductPrice>
                            </ProductRow>
                        )}
                        <ProductRow>
                            <ProductName>License tax:</ProductName>
                            <ProductPrice>
                                <Figures>
                                    <FormattedNumber
                                        style={`currency`}
                                        currency='USD'
                                        value={
                                            (parseFloat(props.taxRate) *
                                                props.cartFromApi.pricing
                                                    .final_price) /
                                            100
                                        }
                                    />
                                </Figures>
                            </ProductPrice>
                        </ProductRow>
                        <ProductRow>
                            <ProductName>License subtotal:</ProductName>
                            <ProductPrice>
                                <Figures>
                                    <FormattedNumber
                                        style={`currency`}
                                        currency='USD'
                                        value={
                                            (props.cartFromApi.pricing
                                                .final_price +
                                                parseFloat(props.taxRate) *
                                                    props.cartFromApi.pricing
                                                        .final_price) /
                                            100
                                        }
                                    />
                                </Figures>
                            </ProductPrice>
                        </ProductRow>
                    </React.Fragment>
                )}
            </Grouping>
            <TotalAppWithTaxCost>
                <ProductName>Total:</ProductName>
                <ProductPrice>
                    <Figures>
                        {props.cartFromApi.pricing ? (
                            <FormattedNumber
                                style={`currency`}
                                currency='USD'
                                value={
                                    parseFloat(
                                        (
                                            (props.cartFromApi.pricing
                                                .final_price +
                                                parseFloat(props.taxRate) *
                                                    props.cartFromApi.pricing
                                                        .final_price) /
                                            100
                                        ).toFixed(2)
                                    ) +
                                    (props.subscriptionEstimate +
                                        props.subscriptionEstimate *
                                            parseFloat(props.taxRate))
                                }
                            />
                        ) : (
                            <FormattedNumber
                                style={`currency`}
                                currency='USD'
                                value={
                                    props.subscriptionEstimate +
                                    props.subscriptionEstimate *
                                        parseFloat(props.taxRate)
                                }
                            />
                        )}
                    </Figures>
                </ProductPrice>
            </TotalAppWithTaxCost>
            <Subscript>All totals are in US Dollars.</Subscript>
        </TotalCost>
    );
};

export default Receipt;
