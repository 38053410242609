import * as React from 'react';
import styled from 'styled-components/macro';

const ErrorHolder = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 45%;
    background-color: yellow;
    color: #000;
    padding: 10px 20px;
    margin: 20px auto;
    max-width: 400px;
`;

interface ErrorProps {
    center?: boolean;
    children: React.ReactNode;
}

export const Error: React.FC<ErrorProps> = ({ children }: ErrorProps) => {
    return <ErrorHolder>{children}</ErrorHolder>;
};
