export interface Customer {
    customer_id: string;
}

export interface CardParams extends Customer {
    card_id: string;
}

export interface PaymentIntentParams extends Customer {
    payment_intent_id: string;
}

export interface SetupIntentParams extends Customer {
    setup_intent_id: string;
}

export interface PaymentMethodParams extends Customer {
    payment_method_id: string;
}

export interface SourceTokenParams extends Customer {
    source_token: string;
}

export interface SubscriptionParams extends Customer {
    subscription_id: string;
}

// const API_V1 = '/api/v1';
const API_V2 = '/api/v2';

// HTTP Method: GET
export const GET_CONF_EP = `${API_V2}/get_conf`;

// HTTP Method: GET
export const GET_PLANS_EP = `${API_V2}/plans`;

// HTTP Method: GET
export const GET_PRODUCTS_EP = `${API_V2}/products`;

// HTTP Method: POST
export const SIGNIN_EP = `${API_V2}/signin`;

// HTTP Method POST
export const SIGNOUT_EP = `${API_V2}/signin`;

// HTTP Method: POST
export const USER_EP = `${API_V2}/user`;

// HTTP Method: POST
export const CART_EP = `${API_V2}/omni/cart`;

// HTTP Method POST
export const CREATE_CUSTOMER_EP = `${API_V2}/customer`;

// HTTP Method GET
export const GET_CUSTOMER_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id})`;

// HTTP Method: POST
export const UPDATE_CUSTOMER_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}`;

// HTTP Method: GET
export const GET_CARDS_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/cards`;

// HTTP Method: GET
export const GET_CARD_EP = ({ card_id, customer_id }: CardParams): string =>
    `${API_V2}/customer/${customer_id}/card/${card_id}`;

// HTTP Method: POST
export const UPDATE_CARD_EP = ({ card_id, customer_id }: CardParams): string =>
    `${API_V2}/customer/${customer_id}/card/${card_id}`;

// HTTP Method: POST
export const DELETE_CARD_EP = ({ card_id, customer_id }: CardParams): string =>
    `${API_V2}/customer/${customer_id}/card/${card_id}/delete`;

// HTTP Method: POST
export const CREATE_CARD_FROM_SOURCE_TOKEN_EP = ({
    customer_id,
    source_token,
}: SourceTokenParams): string =>
    `${API_V2}/customer/${customer_id}/source/${source_token}/new`;

// HTTP Method: GET
export const GET_PAYMENT_METHODS_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/payment_methods`;

// HTTP Method: GET
export const GET_PAYMENT_METHOD_EP = ({
    customer_id,
    payment_method_id,
}: PaymentMethodParams): string =>
    `${API_V2}/customer/${customer_id}/payment_method/${payment_method_id}`;

// HTTP Method: POST
export const CREATE_PAYMENT_METHOD_EP = ({
    customer_id,
    source_token,
}: SourceTokenParams): string =>
    `${API_V2}/customer/${customer_id}/payment_methods/${source_token}/create`;

// HTTP Method: POST
export const ATTACH_PAYMENT_METHOD_EP = ({
    customer_id,
    payment_method_id,
}: PaymentMethodParams): string =>
    `${API_V2}/customer/${customer_id}/payment_method/${payment_method_id}/attach`;

// HTTP Method: POST
export const DETACH_PAYMENT_METHOD_EP = ({
    customer_id,
    payment_method_id,
}: PaymentMethodParams): string =>
    `${API_V2}/customer/${customer_id}/payment_method/${payment_method_id}/detach`;

// HTTP Method: POST
export const SET_PAYMENT_METHOD_AS_DEFAULT_EP = ({
    customer_id,
    payment_method_id,
}: PaymentMethodParams): string =>
    `${API_V2}/customer/${customer_id}/payment_method/${payment_method_id}/default`;

// HTTP Method: POST
export const UPDATE_PAYMENT_METHOD_EP = ({
    customer_id,
    payment_method_id,
}: PaymentMethodParams): string =>
    `${API_V2}/customer/${customer_id}/payment_method/${payment_method_id}/update`;

// HTTP Method: POST
export const CREATE_REFUND_EP = ({
    customer_id,
    payment_intent_id,
}: PaymentIntentParams): string =>
    `${API_V2}/customer/${customer_id}/refund/${payment_intent_id}/create`;

// HTTP Method: POST
export const CREATE_SETUP_INTENT_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/setup_intent`;

// HTTP Method: POST
export const CANCEL_SETUP_INTENT_EP = ({
    customer_id,
    setup_intent_id,
}: SetupIntentParams): string =>
    `${API_V2}/customer/${customer_id}/setup_intent/${setup_intent_id}/cancel`;

// HTTP Method: POST
export const UPDATE_SETUP_INTENT_EP = ({
    customer_id,
    setup_intent_id,
}: SetupIntentParams): string =>
    `${API_V2}/customer/${customer_id}/setup_intent/${setup_intent_id}/update`;

// HTTP Method: POST
export const CONFIRM_SETUP_INTENT_EP = ({
    customer_id,
    setup_intent_id,
}: SetupIntentParams): string =>
    `${API_V2}/customer/${customer_id}/setup_intent/${setup_intent_id}/confirm`;

// HTTP Method: GET
export const GET_PAYMENT_INTENTS_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/payment_intents`;

// HTTP Method: GET
export const GET_PAYMENT_INTENT_EP = ({
    customer_id,
    payment_intent_id,
}: PaymentIntentParams): string =>
    `${API_V2}/customer/${customer_id}/payment_intent/${payment_intent_id}`;

// HTTP Method: POST
export const CREATE_PAYMENT_INTENT_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/payment_intent`;

// HTTP Method: POST
export const UPDATE_PAYMENT_INTENT_EP = ({
    customer_id,
    payment_intent_id,
}: PaymentIntentParams): string =>
    `${API_V2}/customer/${customer_id}/payment_intent/${payment_intent_id}/update`;

// HTTP Method: POST
export const CONFIRM_PAYMENT_INTENT_EP = ({
    customer_id,
    payment_intent_id,
}: PaymentIntentParams): string =>
    `${API_V2}/customer/${customer_id}/payment_intent/${payment_intent_id}/confirm`;

// HTTP Method: POST
export const CAPTURE_PAYMENT_INTENT_EP = ({
    customer_id,
    payment_intent_id,
}: PaymentIntentParams): string =>
    `${API_V2}/customer/${customer_id}/payment_intent/${payment_intent_id}/capture`;

// HTTP Method: POST
export const CREATE_SUBSCRIPTION_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/subscribe`;

// HTTP Method: POST
export const CREATE_PURCHASE_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/subscribe`;

// HTTP Method: POST
export const CONFIRM_PURCHASE_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/subscribe`;

// HTTP Method: GET
export const GET_SUBSCRIPTIONS_EP = ({ customer_id }: Customer): string =>
    `${API_V2}/customer/${customer_id}/subscriptions`;

// HTTP Method: GET
export const GET_SUBSCRIPTION_EP = ({
    customer_id,
    subscription_id,
}: SubscriptionParams): string =>
    `${API_V2}/customer/${customer_id}/subscription/${subscription_id}`;

// HTTP Method: POST
export const UPDATE_SUBSCRIPTION_EP = ({
    customer_id,
    subscription_id,
}: SubscriptionParams): string =>
    `${API_V2}/customer/${customer_id}/subscription/${subscription_id}`;

// HTTP Method: POST
export const CANCEL_SUBSCRIPTION_EP = ({
    customer_id,
    subscription_id,
}: SubscriptionParams): string =>
    `${API_V2}/customer/${customer_id}/subscription/${subscription_id}/cancel`;

// HTTP Method: POST
export const BUNDLE_EP = `${API_V2}/bundle`;

export const GET_ELIGIBLE_UPGRADES = `${API_V2}/eligible_upgrades`;



