//Colors
export const blackColor = '#000000';
export const brightRed = 'rgba(255, 0, 0, 0.71)';
export const darkGray = '#7F7F7F';
export const darkerGray =' #404040';
export const disabledLightGray = '#f2f2f2';
export const signinDisabledGray = "#969696";
export const borderQuantityGray = "#808080";
export const lightestGray = '#f0f0f0';
export const lightGray = "#ccc";
export const lightBlue = 'rgba(105, 201, 237, 1)';
export const blue = 'rgba(48, 140, 255, 1.00)';
export const whiteColor = '#ffffff';
export const errorBoxLightYellow = '#FFFFBF';
export const transparentWhite = 'rgba(255,255,255, 0.5)';
export const transparentBlack = 'rgba(0, 0, 0, 0.5)';

//OmniFocus colors
export const purple = '#9B59FF';
export const darkPurple = '#7B35E5';

//OmniGraffle colors
export const green = '#75ECA6';
export const darkGreen = '#0CE565';
export const lightGreen = "#FAFFFA";

//OmniOutliner colors
export const orange = '#FA9664';
export const darkOrange = '#FA7B3B';
export const lightOrange = '#FAF8F5';

//OmiPlan colors
export const yellow = '#FFCC00';
export const lightYellow = '#F5DE85';
export const darkYellow = '#D9AE00';
export const backgroundYellow = '#FFFEFA';