import { store } from '..';
import { GET_ELIGIBLE_UPGRADES } from '../constants/PurchaseEndpoints';
import { setEligibleLicenseUpgrades } from '../actions/index';
import { Action } from '../constants/ActionTypes';

interface UpgradeParams {
    accessToken: string;
    purchaseApiUrl: string;
}

const getEligibleUpgrades = async ({
    accessToken,
    purchaseApiUrl,
}: UpgradeParams): Promise<Action> => {
    try {
        const upgrades = await fetch(`${purchaseApiUrl}${GET_ELIGIBLE_UPGRADES}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        });

        const response = await upgrades.json();

        if (response) {
            return store.dispatch(setEligibleLicenseUpgrades(response))
        } else {
            console.log('Unable to pull eligible upgrades')
        }
    } catch (error) {
        console.log('There was an error pulling eligible subscriptions: ', error)
    }
}

export default getEligibleUpgrades;