import * as React from 'react';
import styled from 'styled-components';

import * as vars from '../styles/variables';

interface StyleProps {
    color?: string;
    product?: string;
}

const BuyOptionToggle = styled.div<StyleProps>`
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    line-height: 2.5em;
    margin: auto;
    width: 300px;
    font-weight: 500;

    .switch {
        display: inline-block;
        position: relative;
        height: 40px;
        width: 300px;
        border: 1px solid ${props => props.color};
        border-radius: 20px;
        padding: 0 40px 0 60px;

        > span {
            font-size: 18px;
            color: ${vars.lightGray};
        }

    }

    #checkbox {
        display: none;

        &:checked + .slider {
            background-color: transparent;
        }
        &:checked + .slider:before {
            transform: translateX(142px);
            content: "Upgrade";
            text-align: center;
            width: 160px;
        }
    }

    .slider {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: .4s;
        background-color: transparent;
        cursor: pointer;
        font-size: 18px;

        &:before {
            position: absolute;
            top: 0;
            left: -1px;
            height: 40px;
            width: 150px;
            transition: .5s;
            background-color: ${props => props.color};
            color:  ${props => props.product === 'omnifocus'
                ? vars.whiteColor
                : vars.blackColor
            };
            border-radius: 20px;
            content: "Buy";
            text-align: center;
        }
    }

    .round {
        border-radius: 34px;
    }
    .upgrade-label {
        float: right;
    }
`;

interface ToggleProps {
    color: string;
    shortName: string;
    upgradeContent: boolean;

    setUpgradeContent: (display: boolean) => void;
}

const BuyUpgradeToggle: React.FC<ToggleProps> = (props) => {
    const { 
        color,
        // shortName,
        upgradeContent,
        setUpgradeContent,
    } = props;

    return (
        <BuyOptionToggle 
            aria-label="Buy or Upgrade Options"
            color={color}
            product={props.shortName}
            role="button"
        >
            <label className="switch">
                <span>Buy</span>
                <input 
                    type="checkbox" 
                    id="checkbox" 
                    checked={upgradeContent}
                    onChange={() => setUpgradeContent(!upgradeContent)}
                />
                <div className="slider round"></div>
                <span 
                    className="upgrade-label"
                    aria-label="Upgrade Options"
                >
                    Upgrade
                </span>
            </label> 
        </BuyOptionToggle>
    )
}

export default BuyUpgradeToggle;