import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CartLink = styled(Link)`
    text-decoration: none;
    text-align: center;
`;

interface CartIconProps {
    color: string;
}

const CartIcon: React.FC<CartIconProps> = (props: CartIconProps) => {
    const subscriptionItems = useSelector(
        (state: OmniStore.GlobalReduxState) => state.cart.cart.subscriptions.length
    );
    const licenseItems = useSelector(
        (state: OmniStore.GlobalReduxState) => state.cart.cart.licenses.length
    );
    const upgradeItems = useSelector(
        (state: OmniStore.GlobalReduxState) => state.cart.cart.upgrades.length
    );

    const hideCart = useSelector((state: OmniStore.GlobalReduxState) => state.cart.hideCart);

    if (hideCart) return null;
    if (subscriptionItems || licenseItems || upgradeItems) {
        return (
            <div style={{ float: 'right' }}>
                <CartLink to='/cart'>
                    <svg
                        x='0px'
                        y='0px'
                        width='32px'
                        height='32px'
                        viewBox='-52 -0.9 38.6 37.9'
                        style={{}}
                        enableBackground='new -52 -0.9 38.6 37.9'
                    >
                        <g>
                            <g>
                                <path
                                    style={{
                                        fill: 'none',
                                        stroke: `${props.color}`,
                                        strokeWidth: '1.7',
                                    }}
                                    d='M-23.4,28.2c-5.3,0-18.8,0-18.8,0c-0.9,0-1.9-0.7-2.1-1.7l-3.7-14c-0.2-0.9-1.2-1.7-2.1-1.7h-1.8'
                                />
                            </g>
                            <circle
                                style={{ fill: `${props.color}` }}
                                cx='-39.5'
                                cy='33.5'
                                r='3.3'
                            />
                            <circle
                                style={{ fill: `${props.color}` }}
                                cx='-27.9'
                                cy='33.6'
                                r='3.3'
                            />
                            <circle
                                style={{ fill: `${props.color}` }}
                                cx='-24.7'
                                cy='10.4'
                                r='10.8'
                            />
                            <rect
                                x='-32.8'
                                y='6.5'
                                style={{ fill: 'none' }}
                                width='16.3'
                                height='9.4'
                            />
                            <text
                                id='cart-count'
                                textAnchor='middle'
                                width='10'
                                transform='matrix(1 0 0 1 -25 14)'
                                style={{
                                    // fill: '#F6F6F6',
                                    fontSize: '12.4',
                                    fontWeight: "600",
                                }}
                            >
                                {subscriptionItems + licenseItems + upgradeItems}
                            </text>
                            <path
                                style={{
                                    fill: `${props.color}`,
                                }}
                                d='M-25.2,22.7c-6.1,0-11.2-4.7-11.7-10.7h-6.4c-1,0-1.6,0.8-1.4,1.8l2.3,9.9c0.2,1,1.2,1.8,2.3,1.8h14.9c1,0,2-0.8,2.2-1.8l0.2-1.3C-23.5,22.6-24.4,22.7-25.2,22.7z'
                            />
                        </g>
                    </svg>
                </CartLink>
            </div>
        );
    }

    return null;
};

export default CartIcon;
