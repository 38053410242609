import * as React from 'react';
import styled from 'styled-components/macro';

import * as vars from '../styles/variables';

const PurchaseBlockWrapper = styled.div`
    max-width: 650px;
    margin: 3em auto;
    line-height: 1;

    @media screen and (max-width: 400px) { 
        width: 300px;
        font-size: 30px;
    }
`;

const HeaderContent = styled.div`
    text-align: center;
    position: relative;

    > p, h1 {
        color: ${vars.whiteColor};
    }
`;

const Header = styled.h1`
    font-size: 32px;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 1em;
`;

const Underline = styled.div`
    width: 100%;
    background-color: ${vars.lightGray};
    height: 1px;
`;


const PurchaseBlock: React.FC = () => (
    <PurchaseBlockWrapper>
        <HeaderContent>
            <p style={{fontSize: '24px'}}>Purchase your way with a</p>
            <Header>
                <strong>Subscription</strong> or <strong>One-Off Payment</strong>
            </Header>
        </HeaderContent>
        <Underline></Underline>
    </PurchaseBlockWrapper>
);


export default PurchaseBlock;