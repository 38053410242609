import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import PurchaseBlock from '../../components/PurchaseBlock';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQ from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniOutlinerLicenses, OmniOutlinerFeatures } from '../../constants/products';
import * as vars from '../../styles/variables';

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OOContainer = styled.div`
    display: grid;
    grid-template-areas: 
        'subscription license license'
        ' faq faq faq'
        ;
    gap: 30px;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-areas:
            'subscription'
            'license'
            'faq'
        ;
        justify-items: center;
    }
`;

const OmniOutliner: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    const outlinerMessage = 'OmniOutliner 5 Essentials is a new edition of OmniOutliner that is more streamlined than standard editions of the past at a lower price. No upgrade discount is available but purchasing via this upgrade will make you eligible for a reduced cost upgrade to v5 Pro.'

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniOutliner. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniOutlinerDescription' />{' '}
            </AppDescription>
            <PurchaseBlock />
            <OOContainer>
                <SubscriptionBlock
                    backgroundColor={vars.lightOrange}
                    blockableVendingIds={['OMNIOUTLINER_ALL']}
                    color={vars.orange}
                    dateAddedToCart={0}
                    displayName='OmniOutliner Subscription'
                    gridArea={"subscription"}
                    header={'Subscription'}
                    payInterval={false}
                    platform="Mac - iPhone - iPad"
                    quantity={1}
                    requiredOSString='Requires macOS 12, iOS/iPadOS 16, or newer'
                    shortName='omnioutliner'
                    monthlyPrice={499}
                    monthlyStripeID='STRIPE_OMNIOUTLINER_MONTHLY'
                    yearlyPrice={4999}
                    yearlyStripeID='STRIPE_OMNIOUTLINER_ANNUAL'
                />
                <LicenseBlock
                    backgroundColor={vars.lightOrange}
                    color={vars.darkOrange}
                    dateAddedToCart={0}
                    displayName='OmniOutliner 5 Licenses for Mac'
                    features={OmniOutlinerFeatures}
                    header={['v5 Essentials License', 'v5 Pro License']}
                    omnioutlinerMessage={outlinerMessage}
                    platform="Mac only"
                    products={OmniOutlinerLicenses}
                    productVersion='v5'
                    quantity={1}
                    shortName='omnioutliner'
                    standardOrPro='PRO'
                /> 
                <FAQ/>
            </OOContainer> 
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omnioutliner' />
        </StoreContainer>
    );
};

export default OmniOutliner;
