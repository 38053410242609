import  React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { FormattedNumber } from 'react-intl';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useNavigate } from 'react-router-dom';

import * as vars from '../styles/variables';
import NewButton from './NewButton';
import { addSubscriptionToCart } from '../actions/cart';

interface StyleProps {
    color?: string;
    backgroundColor?: string;
    borderHighlight?: boolean;
    product?: string;
    gridArea?: string;
}

const SubscriptionWrapper = styled.div<StyleProps>`
    grid-area: ${props => 
        props.gridArea === 'offtw' 
        ? css`offtw`
        : css`subscription`
    };
    background-color: ${props => props.backgroundColor};
    border-radius: 7px 7px 0 0;

    @media screen and (max-width: 1000px) {
        justify-self: center;
    }
`;
const SubscriptionBox = styled.div<StyleProps>`
    border: 1px solid ${props => props.color};
    width: 300px;
    border-radius: 5px 5px 0 0;
    text-align: center;    
    height: 100%;
`;

const Header = styled.div<StyleProps>`
    font-size: 20px;
    font-weight: 500;
    padding: 15px;
    color: ${props => props.product === 'omnifocus' 
        ? vars.whiteColor
        : vars.blackColor
    };
    background-color: ${props => props.color};
`;

const Content = styled.div`
    margin-top: 20px;
    border-radius: 10px;

    > p {
        font-size: 14px;
    }

    > ul {
        list-style: none;
    }
`;

const Platform = styled.div`
    font-size: 14px;
    padding: 0 15px;
`;

const PriceContainer = styled.div`
    padding: 1.5em 1em 0.5em;
`;

const Price = styled.div`
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    position: relative;
    padding: 1rem;

    > span {
        font-size: 18px;
        padding: 10px;
    }
`;

const SwitchBox = styled.div`
    font-size: 16px;
    line-height: 2;
`;

const Switch = styled.label`
    position: relative;
    display: inline-block;
    height: 40px;
    width: 45px;
    margin: 0 6px;
    
    > input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

const Slider = styled.span`
    inset: 3px 0px 12px 2px;
    position: absolute;
    cursor: pointer;
    background-color: ${vars.blackColor};
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 4px;
        bottom: 5px; 
        background-color: white;
        border-radius: 50%;
        -webkit-transition: .4s;
        transition: .4s;
    }
`;

const Checkbox = styled.input`
    &:checked + ${Slider} {
        background-color: ${vars.blackColor};
    }
    &:checked + ${Slider}:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    &:focus + ${Slider} {
        box-shadow: 0 0 1px #2196f3;
    }
`;


const Quantity = styled.input<StyleProps>`
    width: 65px;
    padding: 6px 8px;
    margin: 10px 20px 10px 10px;
    border-color: ${(props): string =>
        props.borderHighlight ? 'red' : vars.borderQuantityGray};
    font-family: quatro;
    font-size: 16px;
    line-height: 20px;
    height: unset;
`;

const AddToCart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InCartLink = styled(Link)`
    text-decoration: none;
    color: ${vars.darkerGray};
`;

const FeaturesList = styled.div<StyleProps>`
    margin: 2em 1em 1em 1.5em;
    border-top: 1px solid ${vars.lightGray};
    text-align: start;

    > ul {
        padding: 0;
        margin: 1em;
        padding-bottom: 10px;

        > li {
            padding-bottom: 5px;
            list-style: none;
            
            &::before {
                content: '• ';
                display: inline-block;
                vertical-align: bottom;
                margin-left: -1em;
                width: 1em;
                font-weight: bold;
                font-size: 20px;
                color: ${props => props.color}
            }
        }
    }
`;

interface SubscriptionBlockProps extends OmniStore.SubscriptionCartItem {
    backgroundColor: string;
    blockTitle?: string;
    color: string;
    gridArea: string;
    header: string;
    offtw?: boolean;
}

const SubscriptionBlock: React.FC<SubscriptionBlockProps> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { trackEvent } = useMatomo();

    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const has_auth = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.has_auth);
    
    const accountsApiURL = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const currentSubscriptions = useSelector((state: OmniStore.GlobalReduxState) => ({subscriptions: state.subscriptions.subscriptions }));
    const currentPurchasesFromAccounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);
    
    const [finishedLoading, setFinished] = useState<boolean>(false);
    const [inCart, setInCart] = useState<boolean>(false);
    const initialQuantity = props.quantity ? props.quantity : 2;
    const [quantity, setQuantity] = useState(initialQuantity);
    const [quantityError, setQuantityError] = useState<boolean>(false);
    const [selection, setSelection] = useState<boolean | string>(props.payInterval);
    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [cautiouslySubscribe, setCautiousSubscribed] = useState<boolean>(false);
    
    useEffect(() => {
        if (quantity <= 0) {
            setQuantity(1)
            // setQuantityError(true);
        } else {
            setQuantityError(false);
        }
    }, [quantity]);
    
    // The following looks to see if there’s already a SubscriptionCartItem with the current
    // SubscriptionBlock prop of `displayName`. If it does, we toggle the breakSwitch
    // and then set inCart to `true`
    useEffect(() => {
        cartItems.cart.subscriptions.map((value) => {
            if (value.displayName === props.displayName) {
                setQuantity(value.quantity)
                setInCart(true);
            }
            return null;
        });
        
        if (!currentPurchasesFromAccounts.purchasesData) return;
        if (currentPurchasesFromAccounts.purchasesData.length > 0) {
            currentPurchasesFromAccounts.purchasesData.map((purchase) => {
                const { vending_product_id, subscription_state, source } = purchase;

                if ('vending_product_id' in purchase) {
                    if (props.blockableVendingIds.includes(vending_product_id) && subscription_state === 'active') {
                        if (source === 'stripe') {
                            setSubscribed(true);
                        } else if (source !== 'stripe') {
                            setCautiousSubscribed(true);
                        }
                    }
                }
                return true;
            });
        }
        setFinished(true);
    }, [
        cartItems,
        props.displayName,
        props.monthlyStripeID,
        props.yearlyStripeID,
        currentPurchasesFromAccounts.purchasesData,
        props.blockableVendingIds,
        has_auth
    ]);

    useEffect(() => {
        if (has_auth === false) {
            setFinished(true);
            return;
        }
        // Check the currently subscribed-to subscriptions for team product_ids, then allow them to modify the number of seats on that subscription
        if (currentPurchasesFromAccounts.purchasesData === undefined) return;

        if (currentPurchasesFromAccounts.purchasesData.length > 0) {
            currentPurchasesFromAccounts.purchasesData.map((purchase) => {
                if ('product_id' in purchase) {
                    if (
                        purchase.subscription_state === 'active' &&
                        (purchase.product_id === props.monthlyStripeID ||
                            purchase.product_id === props.yearlyStripeID)
                    ) {
                        setQuantity(purchase.seats);
                        setSubscribed(true);
                    }
                }
                return true;
            });
        }
    }, [
        currentPurchasesFromAccounts,
        props.monthlyStripeID,
        props.yearlyStripeID,
        has_auth,
    ]);


    const addItemToCartHandler = (): void => {
        let alreadySubscribed = false;
        if (finishedLoading === false) return;

        if (currentSubscriptions.subscriptions.length > 0) {
            currentSubscriptions.subscriptions
                .filter((subscription) => {
                    return subscription.subscription_state === 'active';
                })
                .filter((subscription) => {
                    if (
                        subscription.plan.id === props.monthlyStripeID ||
                        subscription.plan.id === props.yearlyStripeID ||
                        (subscription.plan.id.includes('OMNIFOCUS_ALL') &&
                            props.displayName.includes('Web'))
                    ) {
                        alreadySubscribed = true;
                    }
                    return true;
                });
        }
        if (!alreadySubscribed) {
            dispatch(
                addSubscriptionToCart({
                    item: {
                        blockableVendingIds: props.blockableVendingIds,
                        dateAddedToCart: Date.now(),
                        displayName: props.displayName,
                        monthlyPrice: props.monthlyPrice,
                        monthlyStripeID: props.monthlyStripeID,
                        payInterval: selection === true ? 'YEARLY' : 'MONTHLY',
                        quantity: quantity,
                        shortName: props.shortName,
                        yearlyPrice: props.yearlyPrice,
                        yearlyStripeID: props.yearlyStripeID,
                    },
                    noWrite: false,
                })
            );
            trackEvent({
                action: 'Cart Change',
                category: 'Ecommerce',
                name: 'Added to cart: ' + props.displayName, // optional
                value: selection === true ? props.yearlyPrice / 100 : props.monthlyPrice / 100,
            });
            navigate('/cart');
        }
    }

    const product = props.shortName === 'omniplan' ? 'OmniPlan'
        : props.shortName === 'omnifocus' ? 'OmniFocus'
        : props.shortName === 'omnigraffle' ? 'OmniGraffle'
        : 'OmniOutliner'
    ;

    const subscriptionFeatures: Array<string> = [
        `Access latest Pro version of ${product} on all supported platforms`,
        'No major version upgrade cost',
        'Save with annual subscription',
        props.requiredOSString,
        'Auto-renews until canceled',
    ];

    const offtwFeatures: Array<string> = [
        "Web companion access for existing non-subscription OmniFocus customers",
        "Requires existing OmniFocus for Mac, iPad, or iPhone setup",
        "Save with annual subscription",
        "Auto-renews until canceled",
    ]

    return (
        <SubscriptionWrapper 
            gridArea={props.gridArea}
            backgroundColor={props.backgroundColor}
        >
            <SubscriptionBox color={props.color}>
                <Header 
                    color={props.color}
                    product={props.shortName}
                >
                    {props.header}
                </Header>
                <Content>
                    <Platform>{props.platform}</Platform>
                    <PriceContainer>
                        <Price>
                            <FormattedNumber 
                                value={selection === true ? props.yearlyPrice/100 : props.monthlyPrice/100}
                                style={`currency`}
                                currency='USD'
                            />
                            <span>USD</span>
                        </Price>
                        <SwitchBox aria-label="Subscription Options">
                            <span aria-label="Monthly Subscription">Pay monthly</span>
                            <Switch>
                                <Checkbox 
                                    type="checkbox"
                                    onClick={() => setSelection(!selection)}
                                />
                                <Slider></Slider> 
                            </Switch>
                            <span aria-label="Annual Subscription">Pay annually</span>
                        </SwitchBox>
                    </PriceContainer>
                    <AddToCart>
                        {!inCart && !subscribed && (
                            <Quantity 
                                arial-label="Subscription quantity"
                                borderHighlight={quantityError}
                                color={props.color}
                                min={1}
                                placeholder={quantity ? quantity.toString() : '1'}
                                pattern='[0-9]*'
                                type='number'
                                onChange={(e): void => {
                                    setQuantity(Number(e.target.value))
                                }}
                            />
                        )}
                        {finishedLoading && (
                            <>
                                {subscribed ? (
                                    <p>
                                        <strong>
                                            This account has an active subscription. To add more
                                            seats, visit your{' '}
                                            <a href={accountsApiURL}>Omni Accounts</a> page.
                                        </strong>
                                    </p>
                                    ) : cautiouslySubscribe ? (
                                        <p style={{ fontWeight: 600 }}>
                                            You’re already subscribed, but if you think your
                                            subscription might lapse elsewhere, you can start a new
                                            subscription directly with us.
                                        </p>
                                    ) : (
                                        <NewButton
                                            disabled={subscribed || inCart}
                                            id='AddSubscriptionToCartButton'
                                            color={props.color}
                                            omnifocus={props.shortName === 'omnifocus' ? true : false}
                                            onClick={():void => addItemToCartHandler()}
                                        >
                                            {inCart ? (
                                                <InCartLink to={'/cart'}>In Cart</InCartLink>
                                            )   :   'Add to Cart'}
                                        </NewButton>
                                    )
                                }
                            </>
                        )}
                    </AddToCart>
                </Content>
                <FeaturesList color={props.color}>
                    <ul>
                        {props.offtw ? 
                            offtwFeatures.map(feature => (
                                <li key={feature}>{feature}</li>
                            )) 
                            : subscriptionFeatures.map(feature => (
                                <li key={feature}>{feature}</li>
                            ))
                        }
                    </ul>
                </FeaturesList>
            </SubscriptionBox>
        </SubscriptionWrapper>
    )
}

export default SubscriptionBlock;