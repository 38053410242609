import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';

import { RowElement } from '../components/RowElement';
import * as vars from '../styles/variables';

const ColumnElement = styled.div`
    max-width: 45%;
    margin: 0;
    padding: 0 20px;
    color: white;

    > h2, p, a {
        color: ${vars.whiteColor};
    }

    @media (max-width: 600px) {
        width: auto;
        max-width: unset;
    }
`;

const MoreInformation: React.FC = () => {
    const intl = useIntl();

    return (
        <RowElement centered maxWidth='800px' sideMargins>
            <ColumnElement>
                <h2>
                    <FormattedMessage id='tryBeforeYouBuyHeader' />
                </h2>
                <p>
                    <FormattedMessage
                        id='fourteenDayTrial'
                        values={{
                            TrialVersionLink: (
                                <a
                                    href={
                                        'https://support.omnigroup.com/trial-versions/'
                                    }
                                >
                                    {intl.formatMessage({
                                        id: 'fourteenDayTrialLinkText',
                                    })}
                                </a>
                            ),
                        }}
                    />
                </p>
            </ColumnElement>
            <ColumnElement>
                <h2>
                    <FormattedMessage id='moreInformation' />
                </h2>
                <ul>
                    <li>
                        <a href='https://legacy.store.omnigroup.com/lost/'>
                            <FormattedMessage id='lostLicenseLookup' />
                        </a>
                    </li>
                    <li>
                        <a href='https://support.omnigroup.com'>
                            <FormattedMessage id='findAnswers' />
                        </a>
                    </li>
                    <li>
                        <a href='https://support.omnigroup.com/about-our-software-licenses/'>
                            <FormattedMessage id='howOurLicencesWork' />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/legal/privacy/'>
                            <FormattedMessage id='ourPrivacyPolicy' />
                        </a>
                    </li>
                    <li>
                        <a href='https://support.omnigroup.com/our-refund-policy/'>
                            <FormattedMessage id='refundMePlease' />
                        </a>
                    </li>
                    <li>
                        <a href='https://legacy.store.omnigroup.com'>
                            <FormattedMessage id='resellerPurchasing' />
                        </a>
                    </li>
                </ul>
            </ColumnElement>
        </RowElement>
    );
};

export default MoreInformation;
