import { Action, Type } from '../constants/ActionTypes';

//accounts
export const setAccountsInfo = ({ accountInfo, accountsError }): Action => {
    return {
        accountInfo,
        accountsError,
        type: Type.REFRESH_ACCESS_TOKEN,
    }
}

export const setExpandedUserInfo = ({ userinfo }): Action => {
    return {
        type: Type.SET_EXPANDED_USER_INFO,
        userinfo,
    }
}

export const setManagedTeamLicensesInfo = ({ managedTeamLicenses }): Action => {
    return {
        managedTeamLicenses,
        type: Type.SET_MANAGED_TEAM_LICENSES_INFO,
    }
}

export const signInToAccounts = ({ accountInfo, accountsError }): Action => {
    return {
        accountInfo,
        accountsError,
        type: Type.SIGN_IN_TO_ACCOUNTS,
    }
}

export const signOutOfAccounts = (): Action => {
    return {
        type: Type.SIGN_OUT_OF_ACCOUNTS,
    };
};
//not used anywhere - payment
export const addPaymentMethod = ({ item }): Action => {
    return {
        item: item,
        type: Type.ADD_CURRENT_CARD,
    };
};
//not used anywhere - payment
export const verifiedQuantityDiscount = ({ discount }): Action => {
    return {
        discount: discount,
        type: Type.VERIFIED_QUANTITY_DISCOUNT,
    };
};
//cart
export const addSubscriptionToCart = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.ADD_SUBSCRIPTION_TO_CART,
    };
};

export const addLicenseToCart = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.ADD_LICENSE_TO_CART,
    };
};

export const convertSubscriptionToTeam = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.CONVERT_SUBSCRIPTION_TO_TEAM,
    };
};

export const convertLicenseToTeam = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.CONVERT_LICENSE_TO_TEAM,
    };
};

export const clearCart = (): Action => {
    return {
        type: Type.CLEAR_CART,
    };
};

export const fakeClearCart = (): Action => {
    return {
        type: Type.FAKE_CLEAR_CART,
    };
};

export const updateQuantityDiscount = (): Action => {
    return {
        type: Type.QUICK_QUANTITY_DISCOUNT,
    };
};

export const putCartFromAPI = ({ item }): Action => {
    return {
        item,
        type: Type.PUT_CART_FROM_SERVER,
    };
};

export const removeSubscription = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.REMOVE_SUBSCRIPTION,
    };
};

export const removeLicense = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.REMOVE_LICENSE,
    };
};

export const removeLicenseUpgrade = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.REMOVE_LICENSE_UPGRADE,
    };
};

export const togglePayInterval = ({ item, noWrite }): Action => {
    return {
        item: item,
        noWrite: noWrite,
        type: Type.TOGGLE_SUBSCRIPTION_PAY_INTERVAL,
    };
};

export const setPurchasesData = ({ purchasesData }): Action => {
    return {
        purchasesData,
        type: Type.SET_PURCHASES_DATA,
    };
};

export const setInitialSubscriptions = ({ subscriptions }): Action => {
    return {
        subscriptions,
        type: Type.ADD_SUBSCRIPTIONS,
    };
};

export const clearSubscriptions = (): Action => {
    return { type: Type.CLEAR_SUBSCRIPTIONS };
};

export const setStripePublishableKey = ({ publishableKey }): Action => {
    return {
        publishableKey,
        type: Type.SET_STRIPE_PUBLISHABLE_KEY,
    };
};

export const toggleSigninForm = ({ display }): Action => {
    return {
        display: display,
        type: Type.TOGGLE_SIGNIN_FORM,
    };
};

export const setEligibleLicenseUpgrades = (upgrades): Action => {
    return {
        type: Type.SET_ELIGIBLE_UPGRADES,
        upgrades,
    }
}


// export interface AddToSeatListParams {
//     type: string;
//     seat: any;
// }

// export const addToSeatList = ({ value }: Value): Action => {
//     return {
//         type: types.ADD_TO_SEAT_LIST,
//         value,
//     };
// };

// export const saveCustomerInfo = ({ value }: Value): Action => {
//     return {
//         type: types.SAVE_CUSTOMER_INFO,
//         value,
//     };
// };

// export const saveSubscriptionInfo = ({ value }: Value): Action => {
//     return {
//         type: types.SAVE_SUBSCRIPTION_INFO,
//         value,
//     };
// };