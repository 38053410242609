import { REGISTER_EP } from '../constants/AccountsEndpoints';

interface PostRegister {
    accessToken: string;
    accountsApiURL: string;
    payment_intent_id: string;
}

interface PostRegisterResponse {
    errors?: Array<string>;
    status: 'success' | 'error';
}

const postRegisterLicense = async (
    props: PostRegister
): Promise<PostRegisterResponse> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.accountsApiURL}${REGISTER_EP}`, {
            body: JSON.stringify({
                payment_intent: props.payment_intent_id,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${props.accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve(res);
                    } else if (res.errors) {
                        console.log(res.errors);
                        reject(res.errors);
                    }
                    console.log(res);
                    reject('Error posting license registration');
                });
            })
            .catch((err) => {
                console.log('err', err);
                reject(err);
            });
    });
};

export default postRegisterLicense;
