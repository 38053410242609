import * as React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

import * as vars from '../styles/variables';

const FourHolder = styled.div`
    max-width: 800px;
    margin: 30px auto;
    padding: 0 10px;
    text-align: center;

    > h1, h2, p, a {
        color: ${vars.whiteColor};
    }
`;

const FourOhFour: React.FC = () => {
    React.useEffect(() => {
        return function cleanup() {
            const titleTag = document.querySelector(`title`);
            if (titleTag) {
                titleTag.innerText = 'Store - The Omni Group';
            }
        };
    });

    return (
        <FourHolder>
            <Helmet>
                <title>404 - Store - The Omni Group</title>
            </Helmet>

            <h1>404</h1>
            <h2>
                <FormattedMessage id='notFound' />
            </h2>
            <p>
                <a href='/' title='Home'>
                    <FormattedMessage id='returnToStore' />
                </a>
            </p>
        </FourHolder>
    );
};

export default FourOhFour;
