import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import * as vars from '../styles/variables';

interface StyleProps {
    color?: string;
    disabled?: boolean;
    product?: string;
}

const UpgradeFrom = styled.div`
    font-weight: 500;
    font-size: 16px;
`;

const UpgradeItem = styled.div`
    .inCart {
        margin: 0 0 0 auto;
        background-color: ${vars.disabledLightGray}; 
        border: 1px solid ${vars.darkGray};
        padding: 5px 10px;
    }

    #seats-available {
        font-size: 12px;
        margin: -5px 0;
    }
`;

const QuantityContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 15px 0 0;

    > input {
        margin: 0;
        margin-left: auto;
        width: 65px;
        padding: 6px 8px;
    }
`;

const ErrorPrompt = styled.div`
    padding: 10px 0 0;
    font-size: 14px;
    color: ${vars.brightRed};
`;

const Button = styled.button<StyleProps>`
    border: ${props => !props.disabled ? `none` : `1px solid ${vars.darkGray}`};
    border-radius: 20px;
    background-color: ${(props) => !props.disabled ? props.color : vars.disabledLightGray};
    color: ${(props) => props.disabled === true 
        ? vars.darkGray 
        : props.product === 'omnifocus'
        ? vars.whiteColor
        : vars.blackColor
    };
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    margin: 15px 0 0;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
`;

interface FormComponentProps {
    color: string;
    inCart: OmniStore.InCartUpgradeInfo;
    shortName: string;
    standardOrPro: boolean;
    upgradeItems: Array<OmniStore.UpgradeInfo>;
    upgradeType: 'Standard' | 'Pro' | 'Current' | 'Partials';

    addUpgradesToCart: (type: string) => void;
    handleChange: (e: React.FormEvent<HTMLInputElement>, product: OmniStore.UpgradeInfo, upgradeType: string) => void;
}

const FormComponent: React.FC<FormComponentProps> = (props: FormComponentProps) => {
    const [inCartItems, setInCartItems] = React.useState<{[upgradeType: string]: boolean}>({});
    const [numberOfSeatsSelected, setNumberOfSeatsSelected] = React.useState<number>(0);
    const [errorPrompt, setErrorPrompt] = React.useState<{[key: string]: string}>({});
    const [buttonPrompt, setButtonPrompt] = React.useState<string>('Add to cart');
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    
    const upgradeType = props.upgradeType.toUpperCase();

    React.useEffect(() => {
        props.upgradeItems.map((product) => {
            const eligible_upgrade = props.inCart[product.registration_identifier];
            const upgradesAddedToCart = eligible_upgrade && Object.values(eligible_upgrade).reduce((initial, a) => initial + a, 0);
            const seats_available = eligible_upgrade ? product.upgrade_quantity - upgradesAddedToCart : product.upgrade_quantity;

            if (seats_available === 0) {
                setButtonPrompt('Review Cart');
            }
            if (numberOfSeatsSelected === 0) {
                setDisableButton(true);
            }
            if ((seats_available >= numberOfSeatsSelected) && (numberOfSeatsSelected > 0)) {
                setDisableButton(false);
            }
            if (eligible_upgrade && eligible_upgrade[upgradeType]){
                setInCartItems((prevItems) => ({...prevItems, [upgradeType]: true}));
            }
            
        })
    }, [props.inCart, upgradeType, numberOfSeatsSelected])

    React.useEffect(() => {
        if (inCartItems[upgradeType]) {
            setDisableButton(true);
            setButtonPrompt('Review Cart');
        }
    }, [inCartItems])

    const quantityChange = (e, product, seats_available) => {
        const currentVal = Number(e.currentTarget.value);
        setNumberOfSeatsSelected(Number(e.currentTarget.value));

        if (currentVal > seats_available) {
            setErrorPrompt((prevState) => ({ 
                ...prevState, 
                [product.registration_identifier]: 'Too many seats selected'
            }));
        } else if (seats_available >= currentVal) {
            setErrorPrompt((prevState) => ({ 
                ...prevState, 
                [product.registration_identifier]: undefined
            }));
        }
        props.handleChange(e, product, props.upgradeType);
    }

    return (
        <form 
            onSubmit={() => props.addUpgradesToCart(props.upgradeType)}
            >
            {props.standardOrPro && (
                <UpgradeFrom>
                    <FormattedMessage id="availableLicenseUpgrades"/>
                </UpgradeFrom>
            )}
            {props.upgradeItems.map((product, key) => {
                const eligible_upgrade = props.inCart[product.registration_identifier];
                const alreadyInCart = eligible_upgrade && (eligible_upgrade[upgradeType]);
                const upgradesAddedToCart = eligible_upgrade && Object.values(eligible_upgrade).reduce((initial, a) => initial + a, 0);
                const seats_available = eligible_upgrade ? product.upgrade_quantity - upgradesAddedToCart : product.upgrade_quantity;
                
                return (
                    <UpgradeItem key={key}>
                        <QuantityContainer>
                            <div>
                                <div>{product.product_name}</div>
                                <div id="seats-available">
                                    <FormattedMessage 
                                        id="seatsAvailable" 
                                        values={{
                                            numberOfSeats: seats_available
                                        }}
                                    />
                                </div>
                            </div>
                            {alreadyInCart ? (
                                <div className="inCart">in cart</div> 
                                ) : (
                                    <input 
                                        disabled={seats_available < 1 ? true : false}
                                        pattern='[0-9]*'
                                        min={1}
                                        max={seats_available}
                                        type="number"
                                        name={product.registration_identifier}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => quantityChange(e, product, seats_available)}
                                    /> 
                            )}
                        </QuantityContainer>
                        {errorPrompt[product.registration_identifier] && (
                            <ErrorPrompt>{errorPrompt[product.registration_identifier]}</ErrorPrompt>
                        )}
                    </UpgradeItem>
                )
            })} 
            <Button 
                disabled={disableButton}
                color={props.color}
                product={props.shortName}
            >
                {buttonPrompt}
            </Button>
        </form>
    )
}

export default FormComponent;