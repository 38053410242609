import * as ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import * as Sentry from '@sentry/react';

import './styles/index.css';
import App from './app/App';
import configureStore from './utils/configureStore';

// import messages_de from './utils/translations/de.json';
import messages_en from './utils/translations/en.json';

import * as serviceWorker from './serviceWorker';

export const store = configureStore();

const usersLocale = navigator.language;

// const messages: { [key: string]: any } = {
//     'de-DE': messages_de,
//     en: messages_en,
//     'en-US': messages_en,
//     'en-us': messages_en,
// };
const instance = createInstance({
    siteId: 17,
    urlBase: 'https://piwik.omnigroup.com/',
});

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        autoSessionTracking: true,
        dsn: 'https://386dae60df3e41ea8b37695fbe4924e0@sentry.omnigroup.com/5',
        environment:
            process.env.REACT_APP_MAIN_PURCHASE_HOST ===
            'https://purchase.test.omnigroup.com'
                ? 'development'
                : 'production',
        release: process.env.REACT_APP_SENTRY_RELEASE
            ? process.env.REACT_APP_SENTRY_RELEASE
            : undefined,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale={usersLocale} messages={messages_en}>
            <BrowserRouter>
                <MatomoProvider value={instance}>
                    <App />
                </MatomoProvider>
            </BrowserRouter>
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
