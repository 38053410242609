import { CREATE_SETUP_INTENT_EP } from '../constants/PurchaseEndpoints';

/**
 * @typedef PostSetupIntentProps
 * @prop {number} initialAmount
 */
interface PostSetupIntentProps {
    accessToken: string;
    customer_id: string;
    purchaseApiUrl: string;
    usage?: 'off_session';
}

interface PostSetupIntentReturnI {
    success?: { setupIntentClientSecret: string; setupIntentID: string };
    errors?: Array<string>;
}

/**
 * Post a Setup Intent
 * @param {string} customer_id
 * @param {string} usage
 * @return {Promise}
 */
export const postSetupIntent = async ({
    accessToken,
    customer_id,
    purchaseApiUrl,
    usage = 'off_session',
}: PostSetupIntentProps): Promise<PostSetupIntentReturnI> => {
    return await fetch(
        `${purchaseApiUrl}${CREATE_SETUP_INTENT_EP({
            customer_id: customer_id,
        })}`,
        {
            body: JSON.stringify({
                usage: usage ? 'off_session' : null,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        }
    )
        .then((response) => response.json())
        .then((response) => {
            if (!response.errors && response.cs) {
                sessionStorage.setItem('setupIntentClientSecret', response.cs);
                sessionStorage.setItem('setupIntentID', response.id);
                return {
                    success: {
                        setupIntentClientSecret: response.cs,
                        setupIntentID: response.id,
                    },
                };
            } else if (response.errors) {
                console.log(response.errors);
                return { errors: response.errors };
            }
        })
        .catch((err) => {
            console.log('err', err);
            return { errors: err };
        });
};
