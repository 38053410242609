import * as React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NewButton from './NewButton';
import * as vars from '../styles/variables';

interface StyleProps {
    color?: string;
    backgroundColor?: string;
    borderHighlight?: boolean;
    product?: string;
}

const LicenseBox = styled.div<StyleProps>`
    border-radius: 5px;
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.color};

    @media screen and (max-width: 700px) {
        display: none;
        width: 0;
    }
`;

const TopPortoion = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.color};

    > div {
        flex: 1 1;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        padding: 5px;
        margin: 10px 0;
        color: ${props => props.product === 'omnifocus'
            ? vars.whiteColor
            : vars.blackColor
        };

        &:first-child {
            border-right: 1px solid ${vars.lightGray};
        }
    }
`;

const License = styled.div``;

const MiddlePortion = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;

    ${License} {
        flex: 1 1;
        text-align: center;

        &:first-child {
            border-right: 1px solid ${vars.lightGray};
        }
    }
`;

const Platform = styled.div`
    font-size: 14px;
    padding: 0 10px;
`;

const PriceContainer = styled.div`
    padding: 2.5em 1em 0.5em;

    > p {
        font-size: 14px;
    }
`;

const Price = styled.div`
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    position: relative;

    > span {
        font-size: 18px;
        padding: 10px;
    }

`;

const AddToCart = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: center;

    #AddPROLicenseToCartButton, #AddSTANDARDLicenseToCartButton {
        margin: 10px;
    }
`;

const Quantity = styled.input<StyleProps>`
    width: 65px;
    padding: 6px 8px;
    margin: 10px;
    font-family: quatro;
    font-size: 16px;
    line-height: 20px;
    border-color: ${(props): string =>
        props.borderHighlight ? 'red' : vars.borderQuantityGray};
`;

const ReviewCartLink = styled(Link)`
    text-decoration: none;
    color: ${vars.darkerGray};
`;

const CompareFeatures = styled.div<StyleProps>`
    text-align: center;
    
    > a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }
`;

const FeaturesList = styled.div<StyleProps>`
    display: flex;
    justify-content: center;
    text-align: start;
    margin: 1em 1.5em;
    padding: 1em;
    font-size: 16px;
    border-top: 1px solid ${vars.lightGray};

    > div, ul {
        margin: 0;
        padding-left: 1em;
    }
`;

const Item = styled.li<StyleProps>`
    padding-bottom: 5px;
    list-style: none;
    
    &::before {
        content: '• ';
        display: inline-block;
        vertical-align: bottom;
        margin-left: -1em;
        width: 1em;
        font-weight: bold;
        font-size: 20px;
        color: ${props => props.color};
    }
`;

const MobileContainer = styled.div<StyleProps>`
    border-radius: 6px;
    border: 1px solid ${props => props.color};
    background-color: ${props => props.backgroundColor};
    text-align: center;
`;

const MobileTopPortion = styled.div<StyleProps>`
    font-size: 20px;
    background-color: ${props => props.color};
    padding: 15px 0;
    color: ${props => 
        props.product === 'omnifocus' 
        ? vars.whiteColor
        : vars.blackColor
    };
`;

const Content = styled.div`
    margin-top: 20px;

    > p {
        font-size: 14px;
    }

    > ul {
        list-style: none;
    }
`;

interface LicenseBlockProps {
    backgroundColor: string;
    color: string;
    dateAddedToCart: number;
    displayName: string;
    features?: Array<string>;
    finishedLoading: boolean;
    header: Array<string>;
    inCart: boolean;
    platform: string;
    products: Array<OmniStore.ProductInfo>;
    shortName: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    selection: 'PRO' | 'STANDARD'
    proQuantity: number;
    proQuantityError: boolean;
    standardQuantityError: boolean;
    standardQuantity: number;
    usingUpgradePricing: boolean;

    addLicenseToCartHandler: (id: string, price: number) => Promise<void>;
    setSelection: (value: 'STANDARD' | 'PRO') => void;
    setStandardQuantity: (value: number) => void;
    setStandardQuantityError: (value: boolean) => void;
    setProQuantity: (value: number) => void;
    setProQuantityError: (value: boolean) => void;
}

export const BuyLicenseBlock: React.FC<LicenseBlockProps> = (props) => {
    return (
        <LicenseBox backgroundColor={props.backgroundColor} color={props.color}>
            <TopPortoion color={props.color} product={props.shortName}>
                {props.header.map((licenseType) => {
                    return <div key={licenseType}>{licenseType}</div>
                })}
            </TopPortoion>
            <MiddlePortion>
                {props.products.map(license => {
                    const quantity = license.licenseType === 'PRO' ? props.proQuantity : props.standardQuantity;
                    return (
                        <License key={license.vending_product_id}>
                            <Platform>{props.platform}</Platform>
                            <PriceContainer>
                                <Price>
                                    <FormattedNumber 
                                        value={license.price/ 100}
                                        style={`currency`}
                                        currency='USD'
                                    />
                                    <span>USD</span>
                                </Price>
                                <p>
                                    <FormattedMessage id="licensePaymentType"/>
                                </p>
                            </PriceContainer>
                            <AddToCart onSubmit={() => props.addLicenseToCartHandler(license.vending_product_id, license.price)}>
                                {!props.inCart && (
                                    <Quantity 
                                        aria-label="Traditional license quantity"
                                        min={1}
                                        placeholder={quantity ? quantity.toString() : '1'}
                                        pattern='[0-9]*'
                                        type='number'
                                        borderHighlight={license.licenseType === 'PRO' ? props.proQuantityError : props.standardQuantityError}
                                        onChange={(e): void => license.licenseType === 'PRO' 
                                            ? props.setProQuantity(Number(e.target.value))
                                            : props.setStandardQuantity(Number(e.target.value)
                                        )}
                                    />
                                )}
                                {props.finishedLoading && (
                                    <NewButton
                                        alignRight={true}
                                        disabled={props.inCart}
                                        id={`Add${license.licenseType}LicenseToCartButton`}
                                        color={props.color}
                                        omnifocus={props.shortName === 'omnifocus' ? true : false}
                                        onClick={() => props.setSelection(license.licenseType)}
                                    >
                                        {props.inCart ? (
                                            <ReviewCartLink to={'/cart'}>Review Cart</ReviewCartLink>
                                        )   : 'Add to Cart'}
                                    </NewButton>
                                )}
                            </AddToCart>
                        </License>
                    )
                })}
            </MiddlePortion>
            <CompareFeatures>
                <a href={`https://www.omnigroup.com/${props.shortName}/features`}>Compare features &gt;</a>
            </CompareFeatures>
            <FeaturesList>
                <ul>
                    {props.features.map((feature, key) => (
                        <Item color={props.color} key={key}>{feature}</Item>
                    ))}
                </ul>
            </FeaturesList>
        </LicenseBox>   
    );
}     

interface MobileLicenseProps  {
    backgroundColor: string;
    color: string;
    features: Array<string>;
    finishedLoading: boolean;
    header: string;
    inCart: boolean;
    licenseType: 'STANDARD' | 'PRO';
    platform: string;
    price: number;
    productID: string;
    proQuantityError: boolean;
    quantity: number;
    product: string;
    standardQuantityError: boolean;

    addLicenseToCartHandler: (id: string, price: number) => Promise<void>;
    setSelection: (value: 'STANDARD' | 'PRO') => void;
    setStandardQuantity: (value: number) => void;
    setProQuantity: (value: number) => void;
}

export const MobileLicenseBlock: React.FC<MobileLicenseProps> = (props) => {
    return (
        <MobileContainer 
            color={props.color}
            backgroundColor={props.backgroundColor}
            key={props.productID}
        >
            <MobileTopPortion 
                color={props.color}
                product={props.product}
            >
                {props.header}
            </MobileTopPortion>
            <Content>
                <p>{props.platform}</p>
                <PriceContainer>
                    <Price>
                        <FormattedNumber 
                            value={props.price/100}
                            style={`currency`}
                            currency='USD'
                        />
                        <span>USD</span>
                    </Price>
                    <p>Traditional one-off payment</p>
                </PriceContainer>
                <AddToCart onSubmit={() => props.addLicenseToCartHandler(props.productID, props.price)}>
                    {!props.inCart && (
                        <Quantity 
                            min={1}
                            placeholder={props.quantity ? props.quantity.toString() : '1'}
                            pattern='[0-9]*'
                            type='number'
                            value={props.quantity}
                            color={props.color}
                            borderHighlight={
                                props.licenseType === 'PRO' 
                                ? props.proQuantityError 
                                : props.standardQuantityError
                            }
                            onChange={(e): void => props.licenseType === 'PRO' 
                                ? props.setProQuantity(Number(e.target.value))
                                : props.setStandardQuantity(Number(e.target.value)
                            )}
                        />
                    )}
                    {props.finishedLoading && (
                        <NewButton
                            alignRight={true}
                            disabled={props.inCart}
                            id={`Add${props.licenseType}LicenseToCartButton`}
                            color={props.color}
                            omnifocus={props.product === 'omnifocus' ? true : false}
                            onClick={() => props.setSelection(props.licenseType)}
                        >
                            {props.inCart ? (
                                <ReviewCartLink to={'/cart'}>Review Cart</ReviewCartLink>
                            )   : 'Add to Cart'}
                        </NewButton>
                    )}
                </AddToCart>
            </Content>
            <FeaturesList color={props.color}>
                <ul>
                    {props.features.map(feature => (
                        <li key={feature}>{feature}</li>
                    ))}
                </ul>
            </FeaturesList>
        </MobileContainer>
    )
}

