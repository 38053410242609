import * as React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import PurchaseBlock from '../../components/PurchaseBlock';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQ from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniGraffleLicenses, OmniGraffleFeatures } from '../../constants/products';
import * as vars from '../../styles/variables';

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OGContainer = styled.div`
    display: grid;
    grid-template-areas: 
        'subscription license license'
        'faq faq faq'
    ;
    gap: 30px;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-areas:
            'subscription'
            'license'
            'faq'
        ;
        justify-items: center;
    }
`;


const OmniGraffle: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniGraffle. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniGraffleDescription' />{' '}
            </AppDescription>
            <PurchaseBlock />
            <OGContainer>
                <SubscriptionBlock
                    backgroundColor={vars.lightGreen}
                    blockableVendingIds={['OMNIGRAFFLE_ALL']}
                    color={vars.green}
                    dateAddedToCart={0}
                    displayName='OmniGraffle Subscription'
                    gridArea={"subscription"}
                    header={'Subscription'}
                    payInterval={false}
                    platform="Mac, iPhone, and iPad"
                    quantity={1}
                    requiredOSString='Requires macOS 12, iOS/iPadOS 16 or newer'
                    shortName='omnigraffle'
                    monthlyPrice={1249}
                    monthlyStripeID='STRIPE_OMNIGRAFFLE_MONTHLY'
                    yearlyPrice={12499}
                    yearlyStripeID='STRIPE_OMNIGRAFFLE_ANNUAL'
                />
                <LicenseBlock
                    backgroundColor={vars.lightGreen}
                    color={vars.darkGreen}
                    dateAddedToCart={0}
                    displayName='OmniGraffle 7 Licenses for Mac'
                    features={OmniGraffleFeatures}
                    header={['v7 Standard License', 'v7 Pro License']}
                    platform='Mac only'
                    products={OmniGraffleLicenses}
                    productVersion='v7'
                    quantity={1}
                    shortName='omnigraffle'
                    standardOrPro='PRO'
                />
                <FAQ />
            </OGContainer>
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omnigraffle' />
        </StoreContainer>
    );
};

export default OmniGraffle;
