interface GetConfReturn {
    revision: string;
}

const getConfiguration = async (): Promise<GetConfReturn> => {
    return await new Promise((resolve, reject) => {
        fetch(`/conf`, {
            cache: 'reload',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => response.text())
            .then((data) => {
                const jsonedData = JSON.parse(data);
                resolve({ revision: jsonedData.revision });
            })
            .catch(() => {
                reject({ revision: 'development' });
            });
    });
};

export default getConfiguration;
